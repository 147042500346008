import React, { useState, useEffect, useContext } from "react";

// Bootstrap components
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

// Custom styles
import "./newsLetter.styles.scss";

// Context
import AlertContext from "../../contexts/AlertContext";

// Formik for form validation
import { Formik } from "formik";

import * as yup from "yup";

const schema = yup.object().shape({
  firstName: yup.string().required("Este campo es requerido"),
  lastName: yup.string().required("Este campo es requerido"),
  email: yup
    .string()
    .required("Este campo es requerido")
    .email("Ingresa un email válido"),
});

function Newsletter() {
  const [loading, setLoading] = useState(false);
  const alert = useContext(AlertContext);

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
      }}
      onSubmit={async (values, { resetForm }) => {
        console.log(values);
        setLoading(true);
        const options = {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
          },
        };
        try {
          fetch(`${process.env.REACT_APP_API_URL}/clients`, options)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json(); // Parse the response body as JSON
            })
            .then((data) => {
              setLoading(false);

              if (data.error) {
                alert.error(
                  `Se ha producido un error al crear tu suscripción. ${data.error}`
                );
              } else {
                alert.success(
                  "¡Te has suscrito correctamente nuestro newsletter!"
                );
              }
            });
        } catch (error) {
          setLoading(false);
          alert.error(
            `Se ha producido un error al crear tu suscripción. ${error}`
          );
          console.error(
            "There has been a problem with creating client.",
            error
          );
        }
        resetForm();
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit} className="newsletter-form">
          <Row>
            <Col sm="12" md="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="Escribe tu nombre"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.firstName && !!errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName || touched.firstName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm="12" md="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="Escribe tu apellido"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.lastName && !!errors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastName || touched.lastName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm="12" md="4">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="email"
                  placeholder="Correo electrónico"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email || touched.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <button type="submit" disabled={loading}>
                {loading ? (
                  <>
                    <Spinner animation="border" variant="light" /> Cargando...
                  </>
                ) : (
                  "Inscribirme"
                )}
              </button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default Newsletter;
