import React from "react";
import { Link } from "react-router-dom";

import { HashLink } from "react-router-hash-link";

// Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom styles
import "./footer.styles.scss";

// Assets
import logoBlue from "../../assets/images/logo--blue.svg";
import igBlack from "../../assets/images/ig--black.svg";
import fbBlack from "../../assets/images/fb--black.svg";
import tikTokBlack from "../../assets/images/tiktok--black.svg";
import waWhite from "../../assets/images/wa-icon--white.svg";
import foorte from "../../assets/images/footer.png";
import isoLogo from "../../assets/images/isoLogo.svg";

function Footer() {
  return (
    <footer className="footer" id="footer">
      <a href="https://wa.me/+525540988988" class="wa-float" target="_blank">
        <img src={waWhite} alt="Cuult" />
      </a>
      <div className="footer__logo">
        {/* <p>cambiar a azul</p> */}
        <img src={logoBlue} alt="CUULT" />
      </div>
      <div className="footer__content">
        <div className="footer__content__logo">
          {/* <p>cambiar a azul</p> */}
          <img src={isoLogo} alt="CUULT" />
        </div>
        {/* <div className="footer__newsletter">
          <h6>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</h6>
          <p>
            Al suscribirte, aceptas nuestras{" "}
            <a href="">Políticas de privacidad</a>
          </p>
        </div>*/}
        <div className="footer__info">
          <Container>
            <Row>
              <Col lg="3">
                <h6>Mapa del sitio</h6>
                <ul>
                  <li>
                    <Link to="/">Inicio</Link>
                  </li>
                  <li>
                    <a href="/#services">Servicios</a>
                  </li>
                  <li>
                    <a href="/#circuits">Circuitos</a>
                  </li>

                  {/* <li>
                    <Link to="/terminos-y-condiciones">
                      Términos y condiciones
                    </Link>
                  </li> */}
                </ul>
              </Col>
              <Col lg="2">
                <h6>social</h6>
                <ul>
                  <li className="d-flex flex-row align-items-center">
                    <a
                      href="https://www.instagram.com/cuultwellnessclub/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={igBlack} alt="CUUlT" /> instagram
                    </a>
                  </li>
                  {/* <li className="d-flex flex-row align-items-center">
                    <a href="" target="_blank" rel="noopener noreferrer">
                      <img src={fbBlack} alt="CUUlT" /> facebook
                    </a>
                  </li> */}
                  <li className="d-flex flex-row align-items-center">
                    <a
                      href="https://www.tiktok.com/@join.the.cuult?_t=8lm8CmX2ZUs&_r=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={tikTokBlack} alt="CUUlT" /> tiktok
                    </a>
                  </li>
                </ul>
              </Col>
              <Col lg="3">
                <h6>Información</h6>
                <ul>
                  <li>
                    <Link to="/terminos-y-condiciones">
                      Términos y condiciones
                    </Link>
                  </li>
                  <li>
                    <Link to="/preguntas-frecuentes">Preguntas frecuentes</Link>
                  </li>
                  <li>
                    <HashLink to="/terminos-y-condiciones#cancellation">
                      Política de cancelación
                    </HashLink>
                  </li>
                </ul>
              </Col>
              <Col lg="4">
                <h6>Contacto</h6>
                <ul>
                  <li>
                    <span>Horario:</span> Lun-Vie: 9:00 am a 6:00 pm
                    <br />
                    Sábados: 9:00 am a 2:00 pm
                  </li>
                  <li>
                    <a
                      href="https://maps.app.goo.gl/CwK8rDhGETTXUN449"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textAlign: "center",
                        textDecoration: "underline",
                      }}
                    >
                      Consulta nuestra ubicación aquí
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <img src={foorte} alt="" className="img-fluid" srcset="" /> */}
      </div>
    </footer>
  );
}

export default Footer;
