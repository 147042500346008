import React, { useState, useEffect } from "react";

// Custom styles
import "./circuitModal.styles.scss";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Context
import { useBookingData } from "../../contexts/BookingContext";

// Bootstrap components
import Modal from "react-bootstrap/Modal";

// Custom components
import { Stepper } from "react-form-stepper";
import formatMoney from "../../helpers/formatMoney";
import CheckoutForm from "../CheckoutForm/CheckoutForm";

// Assets
import durationIcon from "../../assets/images/duration-icon.svg";

// Formik for form validation
import * as yup from "yup";

const stripePromise = loadStripe(
  // "pk_test_51IdH0sAVT4FwQAAFvqN55rsRMCXmIcFWAvtvuKlczcoN2WKcA4AoQjPXTVL99W30sIojGR0doTL0735OEIr79LZD00FTHfp5ze",
  // "pk_test_51P0s4QE54j0zgca3cZt9yLD5g96S0fYK9iacK0ZiRKaNqvqMJowRDy7xczrM8n8Jhl02YjsNFzW02adkNlffdseR00Z5LquLQR",
  "pk_live_51P0s4QE54j0zgca3YrpJGxenIpPe3CFcW4MnP9GyXFgAC74w9MnBNP8aDp7ARWL2UnNBGXgmAjQil2czAUTGVU5C00opLAe82E",
  {
    locale: "es-419",
  }
);

const schema = yup.object().shape({
  firstName: yup.string().required("Este campo es requerido"),
  lastName: yup.string().required("Este campo es requerido"),
  email: yup
    .string()
    .required("Este campo es requerido")
    .email("Ingresa un email válido"),
  phone: yup.string(),
});

function DataAndPayment({ activeStep, setActiveStep, services }) {
  const { appointmentData, setAppointmentData } = useBookingData();
  // console.log(services);

  const [matchingNames, setMatchingNames] = useState([]);

  const options = {
    mode: "payment",
    amount: parseInt(appointmentData.productSelection.price),
    currency: "mxn",
  };

  useEffect(() => {
    // Find items in parentArray that exist in childArray
    const coincidences = services?.filter((parentItem) =>
      appointmentData?.productSelection?.appointmentTypeIDs?.some(
        (childItem) => childItem === parentItem.id
      )
    );
    // console.log(coincidences);
    // Extract names from the coincidences
    const names = coincidences.map((item) => {
      // Find the item in childArray to get the name
      const foundItem = services.find((child) => child.id === item.id);
      return foundItem ? foundItem.name : null; // Ensure foundItem is not undefined
    });

    setMatchingNames(names);
    console.log("Names of coincidences:", names);
  }, [services, appointmentData]);

  const handleDeleteService = () => {
    console.log("delete service");
    // setAppointmentData((prevState) => ({
    //   ...prevState,
    //   dateSelection: "",
    //   hourSelection: "",
    //   productSelection: {},
    //   confirmationData: {},
    // }));
    // setActiveStep(0);
  };
  return (
    <div className="circuits__data-payment">
      <div>
        <h6 className="text-center pt-lg-2">
          Se deberá pagar el total del circuito y después agendar
          individualmente la citas correspondientes a tu paquete.
        </h6>
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      </div>
      <div className="selected-service">
        <div>
          <div className="selected-service__date__header">
            <span onClick={handleDeleteService}>&times;</span>
          </div>
          <h6>{appointmentData.productSelection.name}</h6>
          <p>{formatMoney(appointmentData.productSelection.price)}</p>
          <p>
            {" "}
            <img
              src={durationIcon}
              alt={appointmentData.productSelection.nam}
            />
            {appointmentData.productSelection.minutes} minutos totales
          </p>
          <p className="selected-service__circuit-content">
            Tu circuito incluye:
          </p>
          <ul>
            {matchingNames.map((product, key) => (
              <li key={key}>{product}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
function Confirmation() {
  return (
    <div className="circuits__data-payment">
      <div>
        <p>this is data and confirmation</p>
      </div>
    </div>
  );
}

function CircuitModal({
  showCircuitsModal,
  handleCircuitModalClose,
  services,
}) {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    { label: "Datos personales y pago" },
    { label: "Confirmación y booking de servicios" },
  ];
  function getSectionComponent(showCircuitsModal) {
    switch (activeStep) {
      case 0:
        return (
          <DataAndPayment
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            services={services}
            // showBookModal={showBookModal}
          />
        );
      case 1:
        return (
          <Confirmation
            setActiveStep={setActiveStep}
            // showBookModal={showBookModal}
          />
        );
      default:
        return null;
    }
  }
  return (
    <Modal
      show={showCircuitsModal}
      onHide={handleCircuitModalClose}
      className="circuits-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Agenda tu cita</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stepper steps={steps} activeStep={activeStep}>
          {/* {steps.map((label, index) => (
        <div className={getStepClasses(index)} key={index}>
          <div>
            <div className="circle">{index + 1}</div>
          </div>
          <div className="label">{label.label}</div>
          {index < steps.length - 1 && <div className="line"></div>}
        </div>
      ))} */}
        </Stepper>
        {getSectionComponent(showCircuitsModal)}
      </Modal.Body>
    </Modal>
  );
}

export default CircuitModal;
