import React, { useContext, createContext, useState } from "react";

// Define a context
const BookingContext = createContext();

export const useBookingData = () => {
  return useContext(BookingContext);
};
{
  // showBookModal,
  // handleBookModalClose,
  // setShowBookModal,
}
export const BookingProvider = ({ children }) => {
  const [appointmentData, setAppointmentData] = useState({
    productSelection: {},
    dateSelection: "",
    hourSelection: "",
    paymentInfo: {},
    confirmationData: {},
    appliedCoupon: false,
    certificate: {},
    selectedAddons: [],
  });
  return (
    <BookingContext.Provider value={{ appointmentData, setAppointmentData }}>
      {children}
    </BookingContext.Provider>
  );
};
