import React from "react";

// Custom styles
import "./navbarTop.styles.scss";

function NavbarTop() {
  return (
    <div className="custom__nav__top wow fadeInUp" data-wow-delay="2s">
      <h6>AHORRA HASTA UN 20% POR APERTURA CON EL CUPON BIENVENIDO24</h6>
    </div>
  );
}

export default NavbarTop;
