import React, { useState, useEffect } from "react";

// Custom styles
import "./packagesModal.styles.scss";

// Bootstrap components
import Modal from "react-bootstrap/Modal";
import Collapse from "react-bootstrap/Collapse";

// Helper
import formatMoney from "../../helpers/formatMoney";

function PackagesModal({
  showPackagesModal,
  handlePackagesModalClose,
  products,
  services,
}) {
  const [sortedProducts, setSortedProducts] = useState([]);
  const getServiceNames = (appointmentTypeIDs) => {
    return appointmentTypeIDs
      .map((id) => {
        const service = services.find((service) => service.id === id);
        return service
          ? { description: service.description, duration: service.duration }
          : null;
      })
      .filter((name) => name !== null);
  };

  useEffect(() => {
    if (products) {
      const sortedProducts2 = [...products].sort((a, b) => {
        // Extract base names by removing the PAQ part
        const baseNameA = a.name.replace(/ \(PAQ \d+\)/, "");
        const baseNameB = b.name.replace(/ \(PAQ \d+\)/, "");

        // If base names are the same, prioritize PAQ 5 over PAQ 10
        if (baseNameA === baseNameB) {
          const isPaq5A = a.name.includes("(PAQ 5)");
          const isPaq5B = b.name.includes("(PAQ 5)");
          return isPaq5A ? -1 : isPaq5B ? 1 : 0;
        }

        // Otherwise, sort alphabetically by base name
        return baseNameA.localeCompare(baseNameB);
      });

      setSortedProducts(sortedProducts2.filter((item) => !item.hidden));
    }
    // console.log(sortedProducts2);
  }, [products]);

  const [openStates, setOpenStates] = useState([]);

  const toggleOpen = (productIndex, serviceIndex) => {
    setOpenStates((prevOpenStates) => {
      const newOpenStates = { ...prevOpenStates };
      const productKey = `product-${productIndex}`;
      if (!newOpenStates[productKey]) {
        newOpenStates[productKey] = [];
      }
      newOpenStates[productKey][serviceIndex] =
        !newOpenStates[productKey][serviceIndex];
      return newOpenStates;
    });
  };

  return (
    <Modal
      show={showPackagesModal}
      onHide={handlePackagesModalClose}
      className="packages-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Agenda tu paquete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {sortedProducts?.map((product, productIndex) => (
          <div className="packages-modal__service" key={productIndex}>
            <div>
              <h5>{product.name}</h5>
              {getServiceNames(product.appointmentTypeIDs).map(
                (service, serviceIndex) => (
                  <div key={serviceIndex}>
                    <span
                      onClick={() => toggleOpen(productIndex, serviceIndex)}
                      aria-controls={`package-description-text-${productIndex}-${serviceIndex}`}
                      aria-expanded={
                        openStates[`product-${productIndex}`]?.[serviceIndex] ||
                        false
                      }
                    >
                      Saber más
                    </span>
                    <Collapse
                      in={
                        openStates[`product-${productIndex}`]?.[serviceIndex] ||
                        false
                      }
                    >
                      <div
                        id={`package-description-text-${productIndex}-${serviceIndex}`}
                      >
                        <p className="packages-modal__service__description">
                          {service.description}
                        </p>
                      </div>
                    </Collapse>

                    {/* <p>{service.duration}</p> */}
                  </div>
                )
              )}

              <p className="packages-modal__service__content">{`Paquete con ${
                product.appointmentTypeCounts[product.appointmentTypeIDs[0]]
              } citas`}</p>
            </div>
            <div className="d-flex flex-column justify-content-between">
              <p className="packages-modal__service__price">
                {formatMoney(product.price)}
              </p>
              <a
                href={`https://app.acuityscheduling.com/catalog.php?owner=31533012&action=addCart&clear=1&id=${product.id}`}
              >
                Reservar
              </a>
            </div>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
}

export default PackagesModal;
