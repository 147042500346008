import React from "react";
import { NavLink, useHistory } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

// Custom components
import { HashLink } from "react-router-hash-link";

// Custom styles
import "./customNav.styles.scss";

// Assets
import logoWhite from "../../assets/images/logo--white.svg";

function CustomNav() {
  function handleCollapse() {
    // console.log("handleCollapse");
    var nav = document.getElementById("basic-navbar-nav");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  }
  return (
    <Navbar
      expand="lg"
      // className={`custom fixed-top ${
      //   active && transparent ? "custom_nav__transparent_active" : "normal"
      // } ${active && !transparent ? "custom_nav_active" : "normal"} ${
      //   disp ? "d-block" : ""
      // } ${disp ? "showingNav" : "notShowingNav"}`}
      className="custom__nav wow fadeInUp"
      data-wow-delay="2.4s"
    >
      <Container>
        <Navbar.Brand as={NavLink} to="/" className="d-block d-lg-none">
          <img src={logoWhite} alt="Cuult" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="border-0"
          id="navbarBtn"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className={`mx-auto custom__nav w-100 justify-content-between align-items-center`}
          >
            <div className="custom__nav__l">
              <Nav.Link
                as={NavLink}
                to="/"
                eventKey={4}
                onClick={handleCollapse}
              >
                Inicio
              </Nav.Link>
              <HashLink
                to="/#services"
                className="nav-link"
                onClick={handleCollapse}
              >
                Servicios
              </HashLink>
              <HashLink
                to="/#circuits"
                className="nav-link"
                onClick={handleCollapse}
              >
                Circuitos
              </HashLink>
              <HashLink
                to="/#footer"
                className="nav-link"
                onClick={handleCollapse}
              >
                Contacto
              </HashLink>
            </div>
            <div className="custom__nav__c">
              <Navbar.Brand as={NavLink} to="/">
                <img src={logoWhite} alt="Cuult" />
              </Navbar.Brand>
            </div>
            <div className="custom__nav__r">
              {/* <Nav.Link
                as={NavLink}
                to="/preguntas-frecuentes"
                eventKey={7}
                onClick={handleCollapse}
              >
                Preguntas frecuentes
              </Nav.Link> */}
              {/* <Nav.Link
                as={NavLink}
                className="custom__nav_cta"
                to="/"
                onClick={handleCollapse}
              >
                Reservar
              </Nav.Link> */}
            </div>
            {/* <HashLink
              to="/#location"
              className="nav-link"
              onClick={handleCollapse}
            >
              Contacto
            </HashLink> */}
            {/* <Nav.Link
          as={NavLink}
          to="/impacto-esg"
          onClick={handleCollapse}
          eventKey={4}
        >
          Impacto ESG
        </Nav.Link>
        <Nav.Link
          as={NavLink}
          to="/blog"
          onClick={handleCollapse}
          eventKey={4}
        >
          Blog
        </Nav.Link>
        <NavDropdown title="Acceso" id="basic-nav-dropdown">
          <NavDropdown.Item
            href="https://consultas.afix.com.mx/consultasApp/auth/consulta"
            onClick={handleCollapse}
          >
            Prospectos
          </NavDropdown.Item>
          <NavDropdown.Item
            href="https://app.afix.com.mx/login_usuarios.php"
            onClick={handleCollapse}
          >
            Clientes
          </NavDropdown.Item>
          <NavDropdown.Item
            href="https://app.worky.mx/iniciar-sesion"
            onClick={handleCollapse}
          >
            Colaboradores
          </NavDropdown.Item>
        </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNav;
