import React, { useState, useEffect, useContext } from "react";

// Bootstrap components
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Collapse from "react-bootstrap/Collapse";

// Context
import AlertContext from "../../contexts/AlertContext";

// Formik for form validation
import { Formik } from "formik";

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

// Custom styles
import "./checkoutForm.styles.scss";

// Assets
import secureIcon from "../../assets/images/secure-icon.svg";

import * as yup from "yup";
import PayPalCheckout from "./PayPalCheckout";
const schema = yup.object().shape({
  firstName: yup.string().required("Este campo es requerido"),
  lastName: yup.string().required("Este campo es requerido"),
  email: yup
    .string()
    .required("Este campo es requerido")
    .email("Ingresa un email válido"),
  phone: yup.string(),
});

// const initialOptions = {
//   clientId:
//     // "AdcSxhjOp4rMYzuxYlaqP8OSujozsbhi9v8xclARGpvysvx37Q3Y5CVjj40m_M0kJcjPDxt8RKnZvBgf",
//     "AeotJJ9HD-BzjrXMDuBj3EOO4CX4Awn5gqeRatfy8J1QXcw0lFmfO9q81mOQbiRW6quwNimzGolkRl8E",
//   currency: "MXN",
//   intent: "capture",
// };

// const onCurrencyChange = ({ target: { value } }) => {
//   setCurrency(value);
//   dispatch({
//     type: "resetOptions",
//     value: {
//       ...options,
//       currency: value,
//     },
//   });
// };

function CheckoutFormNoStripe({
  loading,
  handleDeleteService,
  setLoading,
  appointmentData,
  setAppointmentData,
  setShowBookModal,
}) {
  const [stripeError, setStripeError] = useState("");
  const [visible, setVisible] = useState(false);
  // const [stripeProcessing, setStripeProcessing] = useState(false);
  const [bookingProcessing, setBookingProcessing] = useState(false);

  const [openSecureInformation, setOpenSecureInformation] = useState(false);

  const [openCoupon, setOpenCoupon] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [certificateLoading, setCertificateLoading] = useState(false);
  const [couponSuccess, setCouponSuccess] = useState(false);

  const [certificateResponse, setCertificateResponse] = useState("");

  const alert = useContext(AlertContext);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   const { error, paymentIntent } = await stripe.confirmCardPayment(
  //     clientSecret,
  //     {
  //       payment_method: {
  //         card: elements.getElement(CardElement),
  //         billing_details: {
  //           name: "Jenny Rosen",
  //         },
  //       },
  //     }
  //   );

  //   if (error) {
  //     console.log("Payment failed: ", error);
  //   } else {
  //     console.log("Payment succeeded: ", paymentIntent);
  //     // Handle successful payment (e.g., show success message, redirect to confirmation page)
  //   }
  // };

  useEffect(() => {
    if (stripeError) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
        setStripeError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [stripeError, loading]);

  useEffect(() => {
    if (appointmentData.appliedCoupon) {
      setCouponSuccess(true);

      return () => {};
    }
  }, [appointmentData.appliedCoupon]);

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={async (values, { resetForm }) => {
          setLoading(true);
          values.appointmentTypeID = appointmentData.productSelection.id;
          values.datetime = `${appointmentData.dateSelection}T${appointmentData.hourSelection}`;
          values.certificate = appointmentData.certificate.name;
          values.addonIDs = appointmentData.selectedAddons.map(
            (addon) => addon.id
          );
          const options = {
            method: "POST",
            body: JSON.stringify(values),
            headers: {
              "Content-Type": "application/json",
            },
          };
          // const { error, confirmationToken } =
          //   await stripe.createConfirmationToken({
          //     // type: "card",
          //     // card: elements.getElement(CardElement),
          //     // billing_details: {
          //     //   email: values.email,
          //     //   name: ` ${values.firstName} ${values.lastName}`,
          //     //   phone: values.phone,
          //     //   address: {
          //     //     city: "MX",
          //     //     country: "MX",
          //     //     line1: "MX",
          //     //     line2: "MX",
          //     //     state: "MX",
          //     //   },
          //     elements,
          //     params: {
          //       shipping: {
          //         name: ` ${values.firstName} ${values.lastName}`,
          //         email: values.email,
          //         phone: values.phone,
          //       },
          //     },
          //   });
          // if (confirmationToken) {
          //   console.log(confirmationToken);
          //   try {
          //     fetch(`${process.env.REACT_APP_API_URL}/create-payment-intent`, {
          //       method: "POST",
          //       headers: {
          //         "Content-Type": "application/json",
          //       },
          //       body: JSON.stringify({ amount: 1000, pi: confirmationToken.id }),
          //     })
          //       .then((res) => res.json())
          //       .then((data) => {
          //         // setClientSecret(data.clientSecret);
          //         console.log(data);
          //         setLoading(false);
          //         // Now you might want to redirect user to CheckoutForm or update UI
          //       })
          //       .catch((error) => console.log(error));
          //     //   // fetch(`${process.env.REACT_APP_API_URL}/appointments`, options)
          //     //   //   .then((response) => {
          //     //   //     if (!response.ok) {
          //     //   //       setLoading(false);
          //     //   //       throw new Error("Network response was not ok");
          //     //   //     }
          //     //   //     return response.json(); // Parse the response body as JSON
          //     //   //   })
          //     //   //   .then((data) => {
          //     //   //     console.log(data);
          //     //   //     resetForm();
          //     //   //     handleDeleteService();
          //     //   //     setShowBookModal(false);
          //     //   //     setLoading(false);
          //     //   //   });
          //   } catch (error) {
          //     setLoading(false);
          //     console.error(
          //       "There has been a problem with your fetch operation:",
          //       error
          //     );
          //   }
          // }

          // if (!stripe || !elements) {
          //   return;
          // }

          // const cardElement = elements.getElement(CardElement);

          try {
            try {
              fetch(`${process.env.REACT_APP_API_URL}/appointments`, options)
                .then((response) => {
                  if (!response.ok) {
                    setLoading(false);
                    setBookingProcessing(false);
                    setStripeError(
                      "Hubo un error en agendar tu cita nuestro sistema. Comunícate con nosotros"
                    );
                    setTimeout(() => {
                      setStripeError("");
                    }, 7000);
                    // throw new Error("Network response was not ok");
                  }
                  return response.json(); // Parse the response body as JSON
                })
                .then((data) => {
                  console.log(data);
                  resetForm();
                  handleDeleteService();
                  setBookingProcessing(false);
                  alert.success(
                    `Cita creada con éxito. Por favor revisa la bandeja de tu correo electrónico.`
                  );
                  setShowBookModal(false);
                  setLoading(false);
                });
            } catch (error) {
              console.log(error, "268");
            }
          } catch (error) {
            console.log("error en 293 no stripe");
          }
        }}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Col sm="12" lg="6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Nombre:*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Escribe aquí tu nombre"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.firstName && !!errors.firstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName || touched.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm="12" lg="6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Apellido:*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Escribe aquí tu apellido"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.lastName && !!errors.lastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName || touched.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Email:*</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Correo electrónico"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email || touched.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm="12" lg="6">
                <Form.Group className="mb-3" controlId="formPhone">
                  <Form.Label>Teléfono:</Form.Label>
                  <Form.Control
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    placeholder="(Opcional)"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.phone && !!errors.phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone || touched.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <div
              className="security"
              onClick={() => setOpenSecureInformation(!openSecureInformation)}
              aria-controls="collapse-text"
              aria-expanded={openSecureInformation}
            >
              <img src={secureIcon} alt="" />
              <p>Transacción segura</p>
            </div>
            <Collapse
              in={openSecureInformation}
              className="secureInformation-collapse"
            >
              <div id="collapse-text">
                <p>
                  <span>Plataforma de pagos:</span>{" "}
                  <a
                    href="https://stripe.com/mx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Stripe
                  </a>
                  .
                </p>
                <p className="mt-1">
                  <span>Encriptación:</span> Tu transacción se realiza de forma
                  segura mediante encriptación. Los datos, incluido el número de
                  tarjeta de crédito, se transfieren únicamente a través del
                  protocolo HTTPS con encriptación de hasta 256 bits.
                </p>
              </div>
            </Collapse>
            <div
              className={`security ${couponSuccess && "d-none"}`}
              onClick={() => setOpenCoupon(!openCoupon)}
              aria-controls="collapse-text"
              aria-expanded={openCoupon}
            >
              <p>¿Tienes un cupón?</p>
            </div>
            <Collapse
              in={openCoupon}
              className={`secureInformation-collapse ${
                couponSuccess && "d-none"
              }`}
            >
              <div id="collapse-text">
                <Form>
                  <Form.Group className="mb-3" controlId="formCoupon">
                    <Form.Label>Ingresa el cupón:*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Cupón de descuento"
                      name="coupon"
                      value={coupon}
                      onChange={(e) => setCoupon(e.target.value)}
                    />
                  </Form.Group>
                  <button
                    disabled={coupon === "" || certificateLoading === true}
                    onClick={async (e) => {
                      e.preventDefault();
                      if (coupon !== "") {
                        try {
                          setCertificateLoading(true);
                          fetch(
                            `${
                              process.env.REACT_APP_API_URL
                            }/certificates/check?certificate=${coupon.toLocaleUpperCase()}&appointmentTypeID=${
                              appointmentData.productSelection.id
                            }`
                          )
                            .then((response) => {
                              if (!response.ok) {
                                throw new Error("Network response was not ok");
                              }
                              return response.json(); // Parse the response body as JSON
                            })
                            .then((data) => {
                              if (
                                data.status_code === 400 &&
                                data.error !== "invalid_certificate"
                              ) {
                                setCoupon("");
                                alert.error("Error en el envío de solicitud.");
                                setCertificateLoading(false);
                              } else if (data.error === "invalid_certificate") {
                                setCoupon("");
                                alert.error("Certificado inválido");
                                setCertificateLoading(false);
                              } else {
                                alert.success(
                                  `Cupón del ${data.discountAmount}% aplicado.`
                                );
                                setCoupon("");
                                setAppointmentData((prevState) => ({
                                  ...prevState,
                                  appliedCoupon: true,
                                  certificate: data,
                                  // productSelection: {
                                  //   ...prevState.productSelection,
                                  //   price: (
                                  //     prevState.productSelection.price -
                                  //     prevState.productSelection.price *
                                  //       (data.discountAmount / 100)
                                  //   ).toString(),
                                  // },
                                }));
                                setOpenCoupon(false);
                                setCertificateLoading(false);
                                setCouponSuccess(true);
                                setCertificateResponse(data.certificate);
                              }
                            });
                        } catch (error) {
                          console.error(
                            "There has been a problem with your fetch operation:",
                            error
                          );
                          setCertificateLoading(false);
                        }
                      }
                    }}
                  >
                    {certificateLoading ? "Aplicando cupón..." : "Aplicar"}
                  </button>
                </Form>
              </div>
            </Collapse>
            {/* <p className="payment-type__legend">PayPal</p>
            <Row>
              <PayPalScriptProvider options={initialOptions}>
                <PayPalCheckout />
              </PayPalScriptProvider>
            </Row> */}
            {visible && stripeError && (
              <div className="stripeError">{stripeError}</div>
            )}

            <button type="submit" disabled={loading || openCoupon === true}>
              {/* {loading && !stripeProcessing && !bookingProcessing
              ? "Agendando cita..."
              : stripeProcessing
              ? "Procesando tu pago..."
              : bookingProcessing
              ? "Agendando tu cita"
              : "Agendar cita"} */}
              {loading || bookingProcessing ? (
                <>
                  <Spinner animation="border" variant="light" />{" "}
                  {bookingProcessing
                    ? "Agendando en calendario"
                    : "Agendando cita..."}
                </>
              ) : (
                "Agendar cita"
              )}
            </button>
            <button
              onClick={() => {
                handleDeleteService();
                setCouponSuccess(false);
                setCoupon("");
                setAppointmentData((prevState) => ({
                  ...prevState,
                  appliedCoupon: false,
                }));
              }}
            >
              Cancelar cita
            </button>
            {/* <p>
                ¿No tienes cuenta? <Link to="/sign">Regístrate aquí</Link>
              </p> */}
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CheckoutFormNoStripe;
