import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// Custom SCSS
import "./scss/main.scss";

// Custom components
import CustomNav from "./components/CustomNav/CustomNav";
import NavbarTop from "./components/NavbarTop/NavbarTop";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Alert from "./components/Alert/Alert";

// Pages
import Home from "./pages/Home";
import Hello from "./pages/Hello";
import { BookingProvider } from "./contexts/BookingContext";
import { AlertProvider } from "./contexts/AlertContext";
import Legal from "./pages/Legal";
import Questions from "./pages/Questions";
// import AboutUs from "./pages/AboutUs";
// import ESGImpact from "./pages/ESG";
// import Blog from "./pages/Blog";
// import Article from "./pages/Article";
// import Contact from "./pages/Contact";
// import UNE from "./pages/UNE";
// import Condusef from "./pages/Condusef";
// import Privacy from "./pages/Privacy";
// import Markets from "./pages/Markets";
// import CookieBanner from "./components/CookieBanner/CookieBanner";

function App() {
  return (
    <AlertProvider>
      <Alert />
      <BookingProvider>
        <Router>
          <header>
            {/* <NavbarTop /> */}
            <CustomNav />
          </header>
          <ScrollToTop />
          <Switch>
            <main className="data-scroll-container">
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/preguntas-frecuentes">
                <Questions />
              </Route>
              <Route exact path="/terminos-y-condiciones">
                <Legal />
              </Route>
              {/* <Route exact path="/hello">
                <Hello />
              </Route> */}
              {/* <Route exact path="/nosotros">
            <AboutUs />
          </Route>
          <Route exact path="/impacto-esg">
            <ESGImpact />
          </Route>
          <Route exact path="/blog">
            <Blog />
          </Route>
          <Route exact path="/blog/:id">
            <Article />
          </Route>
          <Route exact path="/mercados">
            <Markets />
          </Route>
          <Route exact path="/contacto">
            <Contact />
          </Route>
          <Route exact path="/unidad-especializada-de-atencion-a-clientes">
            <UNE />
          </Route>
          <Route exact path="/condusef">
            <Condusef />
          </Route>
          <Route exact path="/aviso-de-privacidad">
            <Privacy />
          </Route> */}
            </main>
          </Switch>
          <Footer />
          {/* <CookieBanner /> */}
        </Router>
      </BookingProvider>
    </AlertProvider>
  );
}

export default App;
