import React, { useState, useEffect, createContext, useContext } from "react";

import Modal from "react-bootstrap/Modal";

import { Stepper } from "react-form-stepper";

import "./bookModal.styles.scss";

// Context
import { useBookingData } from "../../contexts/BookingContext";

// Bootstrap components
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

// Custom components
import formatMoney from "../../helpers/formatMoney";
import CheckoutForm from "../CheckoutForm/CheckoutForm";
import CheckoutFormNoStripe from "../CheckoutForm/CheckoutFormNoStripe";
// import StripePayment from "../StripePayment/StripePayment";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

// Assets
import durationIcon from "../../assets/images/duration-icon.svg";

const stripePromise = loadStripe(
  // "pk_test_51P0s4QE54j0zgca3cZt9yLD5g96S0fYK9iacK0ZiRKaNqvqMJowRDy7xczrM8n8Jhl02YjsNFzW02adkNlffdseR00Z5LquLQR",
  "pk_live_51P0s4QE54j0zgca3YrpJGxenIpPe3CFcW4MnP9GyXFgAC74w9MnBNP8aDp7ARWL2UnNBGXgmAjQil2czAUTGVU5C00opLAe82E",
  {
    locale: "es-419",
  }
);

const schema = yup.object().shape({
  firstName: yup.string().required("Este campo es requerido"),
  lastName: yup.string().required("Este campo es requerido"),
  email: yup
    .string()
    .required("Este campo es requerido")
    .email("Ingresa un email válido"),
  phone: yup.string(),
});

// TO-DO
// 1. When date of book is selected auto take to next slide
//

function AllProducts({
  activeStep,
  setActiveStep,
  stepsLength,
  showBookModal,
}) {
  const { appointmentData, setAppointmentData } = useBookingData();
  const [data, setData] = useState(null);
  const [groupedServices, setGroupedServices] = useState({});
  const [selectedService, setSelectedService] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      if (showBookModal) {
        try {
          fetch(`${process.env.REACT_APP_API_URL}/appointment-types`)
            .then((response) => {
              if (!response.ok) {
                setLoading(false);
                setData({
                  error: "Hubo un error en la consulta de servicios.",
                });
                // throw new Error("Network response was not ok");
              }
              return response.json(); // Parse the response body as JSON
            })
            .then((data) => {
              setLoading(false);
              setData(data);
              // console.log(data);
              if (!data.error) {
                const grouped = data.reduce((acc, service) => {
                  if (!acc[service.category]) {
                    acc[service.category] = [service];
                  } else {
                    acc[service.category].push(service);
                  }
                  return acc;
                }, {});
                // Update the state with the grouped services
                setGroupedServices(grouped);
              }
            });
        } catch (error) {
          setLoading(false);
          setData({ error: "Hubo un error en la consulta de servicios." });
          // console.error(
          //   "There has been a problem with your fetch operation:",
          //   error
          // );
        }
      }
    };

    fetchData();

    // Clean-up function
    return () => {
      // Optional: Cancel any ongoing fetch request or perform other clean-up tasks
    };
  }, [showBookModal]);

  useEffect(() => {
    if (Object.keys(appointmentData.productSelection).length > 0) {
      setActiveStep(1);
    }
    return () => {};
  }, [appointmentData]);

  // useEffect(() => {
  //   const deleteStoredData = () => {
  //     setAppointmentData({
  //       productSelection: {},
  //       dateSelection: "",
  //       hourSelection: "",
  //       paymentInfo: {},
  //       confirmationData: {},
  //     });
  //   };
  //   if (showBookModal === false) {
  //     deleteStoredData();
  //   }

  //   // Clean-up function
  //   return () => {
  //     // Optional: Cancel any ongoing fetch request or perform other clean-up tasks
  //   };
  // }, []);
  return (
    <div className="all-products">
      {loading ? (
        <h2 className="p-4 text-center text-lg-start">Cargando productos...</h2>
      ) : null}
      {!loading && data?.error
        ? // <p className="text-center error">

          // </p>
          // <a
          //   href="https://cuultcenter.as.me/schedule.php"
          //   className="original-workflow"
          // >
          //   Consulta nuestros servicios aquí
          // </a>
          window.location.replace(`https://cuultcenter.as.me/schedule.php`)
        : null}
      <div className="services__data">
        {/* {data?.length > 0 ? (
          data.map((service, key) => (
            <div className="service" key={key}>
              <input
                type="checkbox"
                name="service"
                id={`serviceType${service.id}`}
                onClick={() => {
                  setSelectedService(service);
                  setAppointmentData((prevState) => ({
                    ...prevState,
                    productSelection: service,
                  }));
                }}
                checked={selectedService === service}
              />
              <label htmlFor={`serviceType${service.id}`}>
                <div
                  className="service__info__media"
                  style={{ backgroundImage: `url(${service.image})` }}
                ></div>
                <div className="service__info">
                  <h5>
                    {service.name}
                    <span>({service.duration} min)</span>
                  </h5>
                  <p>{service.description}</p>
                </div>
                <span>{formatMoney(service.price)}</span>
              </label>
            </div>
          ))
          
        
        ) : (
          <></>
        )} */}
        <Accordion defaultActiveKey="0">
          {" "}
          {Object.entries(groupedServices).map(
            ([categoryName, services], index) => (
              <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header>{categoryName}</Accordion.Header>
                <Accordion.Body>
                  {services.map((service, key) => (
                    // <div key={service.id}>
                    //   <p>{service.name}</p>
                    // </div>
                    <div className="service" key={key}>
                      <input
                        type="checkbox"
                        name="service"
                        id={`serviceType${service.id}`}
                        onClick={() => {
                          // setSelectedService(service);
                          // setAppointmentData((prevState) => ({
                          //   ...prevState,
                          //   productSelection: service,
                          // }));
                          window.location.replace(
                            `https://cuultcenter.as.me/?appointmentType=${service.id},`
                          );
                        }}
                        checked={selectedService === service}
                      />
                      <label htmlFor={`serviceType${service.id}`}>
                        <div
                          className="service__info__media"
                          style={{ backgroundImage: `url(${service.image})` }}
                        ></div>
                        <div className="service__info">
                          <h5>{service.name}</h5>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {service.description
                              .split("\n")
                              .filter((line) => line.trim() !== "")
                              .map((line, index) => (
                                <span key={index}>
                                  {index > 0 ? "+ " : ""}
                                  {line}
                                  <br />
                                </span>
                              ))}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between justify-content-lg-start flex-lg-column h-100">
                          <span>{formatMoney(service.price)}</span>
                          <span className="service__info__duration">
                            <img src={durationIcon} alt={service.name} />(
                            {service.duration} min)
                          </span>
                        </div>
                      </label>
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
              // <div key={index}>
              //   <h3>{categoryName}</h3>
              //   {/* Render services for this category */}
              //   {services.map((service) => (
              //     <div key={service.id}>
              //       {/* Render each service */}
              //       <p>{service.name}</p>
              //       {/* Add more service details as needed */}
              //     </div>
              //   ))}
              // </div>
            )
          )}
        </Accordion>
      </div>
      <div>
        {activeStep !== 0 && activeStep !== stepsLength - 1 && (
          <button onClick={() => setActiveStep(activeStep - 1)}>
            Anterior
          </button>
        )}
        {/* {activeStep !== stepsLength - 1 && (
          <button
            className="stepper-next"
            onClick={() => setActiveStep(activeStep + 1)}
            disabled={
              Object.keys(appointmentData.productSelection).length === 0
            }
          >
            Siguiente
          </button>
        )} */}
        {/* <button onClick={() => setActiveStep(activeStep - 1)}>
            Previous
          </button>
          <button onClick={() => setActiveStep(activeStep + 1)}>Next</button> */}
      </div>
    </div>
  );
}

function StaffSelection({ setActiveStep, showBookModal }) {
  const { appointmentData, setAppointmentData } = useBookingData();
  const [data, setData] = useState(null);
  const [team, setTeam] = useState();
  const [selectedProductCalendarIDs, setSelectedProductCalendarIDs] = useState(
    appointmentData.productSelection.calendarIDs
  );
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      if (showBookModal) {
        try {
          fetch(`${process.env.REACT_APP_API_URL}/calendars`)
            .then((response) => {
              if (!response.ok) {
                setLoading(false);
                throw new Error("Network response was not ok");
              }
              return response.json(); // Parse the response body as JSON
            })
            .then((data) => {
              setData(data);
              setTeam(
                data.filter((staff) =>
                  selectedProductCalendarIDs.includes(staff.id)
                )
              );
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        }
      }
    };

    fetchData();

    // Clean-up function
    return () => {
      // Optional: Cancel any ongoing fetch request or perform other clean-up tasks
    };
  }, [showBookModal]);

  useEffect(() => {
    const deleteStoredData = () => {
      setAppointmentData({
        productSelection: {},
        dateSelection: "",
        hourSelection: "",
        paymentInfo: {},
        confirmationData: {},
      });
      setActiveStep(0);
    };
    if (showBookModal === false) {
      console.log("deleteStoreData");
      deleteStoredData();
    }

    // Clean-up function
    return () => {
      // Optional: Cancel any ongoing fetch request or perform other clean-up tasks
    };
  }, [showBookModal]);

  useEffect(() => {
    if (appointmentData.productSelection.kind === "package") {
      console.log("is package");
      setAppointmentData((prevState) => ({
        ...prevState,
        confirmationData: { staff: { id: 9715591 } },
      }));
      setActiveStep(2);
    }

    // Clean-up function
    return () => {
      // Optional: Cancel any ongoing fetch request or perform other clean-up tasks
    };
  }, [appointmentData]);

  const handleDeleteService = () => {
    console.log("delete service");
    setAppointmentData((prevState) => ({
      ...prevState,
      dateSelection: "",
      hourSelection: "",
      productSelection: {},
      confirmationData: {},
    }));
    setActiveStep(0);
  };

  return (
    <div className="staff-selection">
      {loading ? (
        <div className="px-2">
          <p className="p-4">Cargando información...</p>
        </div>
      ) : (
        <div>
          <p>Prefiero mi cita con:</p>
          <div>
            <button
              onClick={() => {
                setAppointmentData((prevState) => ({
                  ...prevState,
                  confirmationData: { staff: {} },
                }));
                setActiveStep(2);
              }}
            >
              Cualquiera disponible
            </button>
            {team?.map((staff, key) => (
              <button
                key={key}
                onClick={() => {
                  setAppointmentData((prevState) => ({
                    ...prevState,
                    confirmationData: { staff: staff },
                  }));
                  setActiveStep(2);
                }}
              >
                {staff.name}
              </button>
            ))}
          </div>
        </div>
      )}
      <div className="selected-service">
        <div>
          <div className="selected-service__date__header">
            <span onClick={handleDeleteService}>&times;</span>
          </div>
          <h6>{appointmentData.productSelection.name}</h6>
          <p>{formatMoney(appointmentData.productSelection.price)}</p>
          <p>
            {" "}
            <img
              src={durationIcon}
              alt={appointmentData.productSelection.nam}
            />
            {appointmentData.productSelection.duration} min
          </p>
        </div>
      </div>
    </div>
  );
}

function Calendar({ availableDates, setActiveStep }) {
  const { appointmentData, setAppointmentData } = useBookingData();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [availableDates2, setAvailableDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState([]);

  const handleSelectDate = (date) => {
    setAppointmentData((prevState) => ({
      ...prevState,
      dateSelection: date,
    }));
  };

  const goToPreviousMonth = () => {
    setCurrentMonth(
      (prevMonth) =>
        new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1)
    );
  };

  const goToNextMonth = () => {
    setCurrentMonth(
      (prevMonth) =>
        new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 1)
    );
  };

  const generateDatesForMonth = (month, availableDates2) => {
    if (!month) return [];
    const firstDayOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
    const lastDayOfMonth = new Date(
      month.getFullYear(),
      month.getMonth() + 1,
      0
    );
    const today = new Date();
    const dates = [];

    for (
      let date = new Date(firstDayOfMonth);
      date <= lastDayOfMonth;
      date.setDate(date.getDate() + 1)
    ) {
      // Skip past dates in the current month
      if (
        date < today.setHours(0, 0, 0, 0) &&
        date.getMonth() === today.getMonth()
      ) {
        continue;
      }

      // Skip Sundays
      if (date.getDay() === 0) {
        continue;
      }

      const dateString = date.toISOString().slice(0, 10);
      if (
        availableDates2.some(
          ({ date: availableDate }) => availableDate === dateString
        )
      ) {
        dates.push(new Date(date));
      }
    }

    console.log("Generated Dates:", dates); // Debugging line
    return dates;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_API_URL
          }/availability-dates?date=${currentMonth
            .toISOString()
            .slice(0, 7)}&appointmentTypeID=${
            appointmentData.productSelection.id
          }`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setAvailableDates(data);
      } catch (error) {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentMonth, appointmentData]);

  useEffect(() => {
    const dates = generateDatesForMonth(currentMonth, availableDates2);
    setDates(dates);
  }, [currentMonth, availableDates2]);

  const firstDayIndex =
    (new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay() +
      6) %
    7;

  const isEarliestMonth = () => {
    const today = new Date();
    return (
      currentMonth.getFullYear() === today.getFullYear() &&
      currentMonth.getMonth() === today.getMonth()
    );
  };

  const generateDaysOfWeek = () => {
    const daysOfWeek = [
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    return daysOfWeek.map((day, index) => (
      <div key={index} className="calendar-day-name">
        {day}
      </div>
    ));
  };

  // const getPaddingDays = (month) => {
  //   const firstDayOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
  //   const dayOfWeek = (firstDayOfMonth.getDay() + 6) % 7; // Adjust for Monday start
  //   console.log("Padding days for", month.toISOString(), ":", dayOfWeek); // Debugging line
  //   return new Array(dayOfWeek).fill(null);
  // };
  const getPaddingDays = (month) => {
    const firstDayOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
    let dayOfWeek = (firstDayOfMonth.getDay() + 6) % 7; // Adjust for Monday start
    console.log(firstDayOfMonth);
    console.log(dayOfWeek);
    if (month.getMonth() === 5) {
      // June
      dayOfWeek = 3; // Change padding days to 3 for June
    }
    return new Array(dayOfWeek).fill(null);
  };
  // const getPaddingDays = (month) => {
  //   const firstDayOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
  //   const today = new Date();
  //   const isCurrentMonth =
  //     today.getFullYear() === month.getFullYear() &&
  //     today.getMonth() === month.getMonth();
  //   let dayOfWeek = (firstDayOfMonth.getDay() + 6) % 7; // Adjust for Monday start

  //   // Calculate the padding days
  //   const paddingDays = new Array(dayOfWeek).fill(null);

  //   // Calculate the days that have transpired so far in the current month
  //   let transpiredDays = [];
  //   if (isCurrentMonth) {
  //     const daysPassed = today.getDate();
  //     transpiredDays = Array.from({ length: daysPassed - 1 }, (_, i) => i + 1);
  //   }

  //   // Combine padding days with transpired days
  //   const totalDays = [...transpiredDays];

  //   console.log(firstDayOfMonth);
  //   console.log(dayOfWeek);
  //   console.log(totalDays);

  //   return totalDays;
  // };
  // const getPaddingDays = (month) => {
  //   const firstDayOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
  //   const today = new Date();
  //   const isCurrentMonth =
  //     today.getFullYear() === month.getFullYear() &&
  //     today.getMonth() === month.getMonth();

  //   // Adjust for Monday start
  //   let dayOfWeek = (firstDayOfMonth.getDay() + 6) % 7;
  //   dayOfWeek = 1;

  //   // Calculate the padding days
  //   const paddingDays = new Array(dayOfWeek).fill(null);

  //   // Calculate the days that have transpired so far in the current month
  //   let transpiredDays = [];
  //   if (isCurrentMonth) {
  //     const daysPassed = today.getDate();
  //     transpiredDays = Array.from({ length: daysPassed - 1 }, (_, i) => i + 1);
  //   }

  //   // Combine padding days with transpired days
  //   const totalDays = [...paddingDays, ...transpiredDays];

  //   console.log(firstDayOfMonth);
  //   console.log(dayOfWeek);
  //   console.log(totalDays);

  //   return totalDays;
  // };

  return (
    <div className="calendar">
      <div className="calendar-header">
        <button onClick={goToPreviousMonth} disabled={isEarliestMonth()}>
          &lt;
        </button>
        <h2>
          {currentMonth.toLocaleString("es", {
            month: "long",
            year: "numeric",
          })}
        </h2>
        <button onClick={goToNextMonth}>&gt;</button>
      </div>
      <div className="calendar-body">
        {loading ? (
          <div className="py-3">Cargando...</div>
        ) : (
          <>
            <div className="calendar-days">{generateDaysOfWeek()}</div>
            <div className="calendar-dates">
              {/* {Array.from({ length: firstDayIndex }, (_, i) => (
                <div
                  key={`padding-${i}`}
                  className="calendar-day padding"
                ></div>
              ))} */}
              {getPaddingDays(currentMonth).map((_, i) => (
                <div
                  key={`padding-${i}`}
                  className="calendar-day padding"
                ></div>
              ))}
              {/* {console.log("First day of the month index:", firstDayIndex)}
              {console.log("Dates to be rendered:", dates)} */}
              {dates.map((date) => (
                <button
                  key={date.toISOString()}
                  onClick={() => {
                    handleSelectDate(date.toISOString().slice(0, 10));
                    setActiveStep(3);
                  }}
                  className="calendar-day"
                >
                  <span>{date.getDate()}</span>
                </button>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

// function Calendar({ availableDates, setActiveStep }) {
//   const [currentMonth, setCurrentMonth] = useState(new Date());
//   const [dates, setDates] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [availableDates2, setAvailableDates] = useState([]);
//   const { appointmentData, setAppointmentData } = useBookingData();
//   const handleSelectDate = (date) => {
//     setAppointmentData((prevState) => ({
//       ...prevState,
//       dateSelection: date,
//     }));
//   };
//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(
//           `${
//             process.env.REACT_APP_API_URL
//           }/availability-dates?date=${currentMonth
//             .toISOString()
//             .slice(0, 7)}&appointmentTypeID=${
//             appointmentData.productSelection.id
//           }`
//         );
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         const data = await response.json();
//         setAvailableDates(data);
//       } catch (error) {
//         console.error(
//           "There has been a problem with your fetch operation:",
//           error
//         );
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [currentMonth, appointmentData]);

//   useEffect(() => {
//     const generateDatesForMonth = (month, availableDates) => {
//       if (!month || !availableDates) return [];
//       const firstDayOfMonth = new Date(
//         month.getFullYear(),
//         month.getMonth(),
//         1
//       );
//       const lastDayOfMonth = new Date(
//         month.getFullYear(),
//         month.getMonth() + 1,
//         0
//       );
//       const today = new Date();
//       const dates = [];

//       for (
//         let date = new Date(firstDayOfMonth);
//         date <= lastDayOfMonth;
//         date.setDate(date.getDate() + 1)
//       ) {
//         if (
//           date < today.setHours(0, 0, 0, 0) &&
//           date.getMonth() === today.getMonth()
//         ) {
//           continue;
//         }

//         if (date.getDay() === 0) {
//           // Skip Sundays
//           continue;
//         }

//         const dateString = date.toISOString().slice(0, 10);
//         if (
//           availableDates.some(
//             ({ date: availableDate }) => availableDate === dateString
//           )
//         ) {
//           dates.push(new Date(date));
//         }
//       }

//       return dates;
//     };

//     setDates(generateDatesForMonth(currentMonth, availableDates));
//   }, [currentMonth, availableDates]);

//   const generateDaysOfWeek = () => {
//     const daysOfWeek = [
//       "Lunes",
//       "Martes",
//       "Miércoles",
//       "Jueves",
//       "Viernes",
//       "Sábado",
//     ];
//     return daysOfWeek.map((day, index) => (
//       <div key={index} className="calendar-day-name">
//         {day}
//       </div>
//     ));
//   };

//   const isEarliestMonth = () => {
//     const today = new Date();
//     return (
//       currentMonth.getFullYear() === today.getFullYear() &&
//       currentMonth.getMonth() === today.getMonth()
//     );
//   };

//   const goToPreviousMonth = () => {
//     setCurrentMonth(
//       (prevMonth) =>
//         new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1)
//     );
//   };

//   const goToNextMonth = () => {
//     setCurrentMonth(
//       (prevMonth) =>
//         new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 1)
//     );
//   };

//   return (
//     <div className="calendar">
//       <div className="calendar-header">
//         <button onClick={goToPreviousMonth} disabled={isEarliestMonth()}>
//           &lt;
//         </button>
//         <h2>
//           {currentMonth.toLocaleString("es", {
//             month: "long",
//             year: "numeric",
//           })}
//         </h2>
//         <button onClick={goToNextMonth}>&gt;</button>
//       </div>
//       <div className="calendar-body">
//         {loading ? (
//           <div className="py-3">Cargando...</div>
//         ) : (
//           <>
//             <div className="calendar-days">{generateDaysOfWeek()}</div>
//             <div className="calendar-dates">
//               {dates.map((date) => (
//                 <button
//                   key={date.toISOString()}
//                   onClick={() => {
//                     handleSelectDate(date.toISOString().slice(0, 10));
//                     setActiveStep(3);
//                   }}
//                   className="calendar-day"
//                 >
//                   <span>{date.getDate()}</span>
//                 </button>
//               ))}
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// }

function DateSelection({ setActiveStep, showBookModal }) {
  const { appointmentData, setAppointmentData } = useBookingData();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Add 1 because getMonth() returns zero-based month index
  const currentYearMonth = `${year}-${month}`;
  // console.log(currentYearMonth, "currentYearMonth");
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      if (currentYearMonth !== "") {
        // console.log(
        //   Object.keys(appointmentData.confirmationData.staff).length > 0
        // );
        if (Object.keys(appointmentData.confirmationData.staff).length > 0) {
          try {
            fetch(
              `${process.env.REACT_APP_API_URL}/availability-dates?date=${currentYearMonth}&appointmentTypeID=${appointmentData.productSelection.id}&calendarID=${appointmentData.confirmationData.staff.id}`
            )
              .then((response) => {
                if (!response.ok) {
                  setLoading(false);
                  throw new Error("Network response was not ok");
                }
                return response.json(); // Parse the response body as JSON
              })
              .then((data) => {
                setData(data);
                setLoading(false);
                console.log(data); // Handle the data from the response
              });
          } catch (error) {
            setLoading(false);
            console.error(
              "There has been a problem with your fetch operation:",
              error
            );
          }
        } else {
          try {
            fetch(
              `${process.env.REACT_APP_API_URL}/availability-dates?date=${currentYearMonth}&appointmentTypeID=${appointmentData.productSelection.id}`
            )
              .then((response) => {
                if (!response.ok) {
                  setLoading(false);
                  throw new Error("Network response was not ok");
                }
                return response.json(); // Parse the response body as JSON
              })
              .then((data) => {
                setData(data);
                setLoading(false);
                console.log(data); // Handle the data from the response
              });
          } catch (error) {
            setLoading(false);
            console.error(
              "There has been a problem with your fetch operation:",
              error
            );
          }
        }
      }
    };

    fetchData();

    // Clean-up function
    return () => {
      // Optional: Cancel any ongoing fetch request or perform other clean-up tasks
    };
  }, []);

  // useEffect(() => {
  //   const deleteStoredData = () => {
  //     setAppointmentData({
  //       productSelection: {},
  //       dateSelection: "",
  //       hourSelection: "",
  //       paymentInfo: {},
  //       confirmationData: {},
  //     });
  //   };
  //   deleteStoredData();

  //   // Clean-up function
  //   return () => {
  //     // Optional: Cancel any ongoing fetch request or perform other clean-up tasks
  //   };
  // }, []);

  useEffect(() => {
    const deleteStoredData = () => {
      setAppointmentData({
        productSelection: {},
        dateSelection: "",
        hourSelection: "",
        paymentInfo: {},
        confirmationData: {},
      });
      setActiveStep(0);
    };
    if (showBookModal === false) {
      console.log("deleteStoreData");
      deleteStoredData();
    }

    // Clean-up function
    return () => {
      // Optional: Cancel any ongoing fetch request or perform other clean-up tasks
    };
  }, [showBookModal]);

  const handleDeleteService = () => {
    console.log("delete service");
    setAppointmentData((prevState) => ({
      ...prevState,
      dateSelection: "",
      hourSelection: "",
      productSelection: {},
      confirmationData: {},
    }));
    setActiveStep(0);
  };

  const handleDeleteDate = () => {
    console.log("delete date");
    setAppointmentData((prevState) => ({
      ...prevState,
      dateSelection: "",
      hourSelection: "",
    }));
  };
  const handleDeleteStaff = () => {
    console.log("delete staff");
    setAppointmentData((prevState) => ({
      ...prevState,
      dateSelection: "",
      hourSelection: "",
      confirmationData: { staff: {} },
    }));
    setActiveStep(1);
  };

  return (
    <div className="date-selection">
      {loading ? (
        <p className="p-4 text-center text-lg-start">Cargando fechas...</p>
      ) : (
        <Calendar availableDates={data} setActiveStep={setActiveStep} />
        // <p>holi</p>
      )}
      {/* <div>
      </div> */}
      <div className="selected-service">
        <div>
          <div className="selected-service__date__header">
            <span onClick={handleDeleteService}>&times;</span>
          </div>
          <h6>{appointmentData.productSelection.name}</h6>
          <p>{formatMoney(appointmentData.productSelection.price)}</p>
          <p>
            <img
              src={durationIcon}
              alt={appointmentData.productSelection.nam}
            />
            {appointmentData.productSelection.duration} min
          </p>
        </div>
        {Object.keys(appointmentData.confirmationData.staff).length > 0 ? (
          <div>
            <div className="selected-service__staff__header">
              <span onClick={handleDeleteStaff}>&times;</span>
            </div>
            <h6>Mi cita es con:</h6>
            <p>{appointmentData.confirmationData.staff.name}</p>
          </div>
        ) : (
          <></>
        )}
        {appointmentData.dateSelection !== "" ? (
          <div>
            <div className="selected-service__hour__header">
              <span onClick={handleDeleteDate}>&times;</span>
            </div>
            <h6>Fecha</h6>
            <p>{appointmentData.dateSelection}</p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
function HourSelection({ setActiveStep, showBookModal }) {
  const { appointmentData, setAppointmentData } = useBookingData();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const deleteStoredData = () => {
      setAppointmentData({
        productSelection: {},
        dateSelection: "",
        hourSelection: "",
        paymentInfo: {},
        confirmationData: {},
      });
      setActiveStep(0);
    };
    if (showBookModal === false) {
      console.log("deleteStoreData");
      deleteStoredData();
    }

    // Clean-up function
    return () => {
      // Optional: Cancel any ongoing fetch request or perform other clean-up tasks
    };
  }, [showBookModal]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      if (Object.keys(appointmentData.confirmationData.staff).length > 0) {
        try {
          fetch(
            `${process.env.REACT_APP_API_URL}/availability-times?date=${appointmentData.dateSelection}&appointmentTypeID=${appointmentData.productSelection.id}&calendarID=${appointmentData.confirmationData.staff.id}`
          )
            .then((response) => {
              if (!response.ok) {
                setLoading(false);
                throw new Error("Network response was not ok");
              }
              return response.json(); // Parse the response body as JSON
            })
            .then((data) => {
              setLoading(false);
              setData(data);
            });
        } catch (error) {
          setLoading(false);
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        }
      } else {
        try {
          fetch(
            `${process.env.REACT_APP_API_URL}/availability-times?date=${appointmentData.dateSelection}&appointmentTypeID=${appointmentData.productSelection.id}`
          )
            .then((response) => {
              if (!response.ok) {
                setLoading(false);
                throw new Error("Network response was not ok");
              }
              return response.json(); // Parse the response body as JSON
            })
            .then((data) => {
              setLoading(false);
              setData(data);
            });
        } catch (error) {
          setLoading(false);
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        }
      }
    };

    fetchData();

    // Clean-up function
    return () => {
      // Optional: Cancel any ongoing fetch request or perform other clean-up tasks
    };
  }, []);
  const handleDeleteService = () => {
    console.log("delete service");
    setAppointmentData((prevState) => ({
      ...prevState,
      dateSelection: "",
      hourSelection: "",
      productSelection: {},
      confirmationData: {},
    }));
    setActiveStep(0);
  };

  const handleDeleteDate = () => {
    console.log("delete date");
    setAppointmentData((prevState) => ({
      ...prevState,
      dateSelection: "",
      hourSelection: "",
    }));
    setActiveStep(1);
  };
  const handleDeleteHour = () => {
    console.log("delete date");
    setAppointmentData((prevState) => ({
      ...prevState,
      hourSelection: "",
    }));
    // setActiveStep(0);
  };

  const handleSelectHour = (hour) => {
    // onSelectDate(date);
    setAppointmentData((prevState) => ({
      ...prevState,
      hourSelection: hour,
    }));
    // const onSelectDate = () => {
    // };
  };

  const handleDeleteStaff = () => {
    console.log("delete staff");
    setAppointmentData((prevState) => ({
      ...prevState,
      dateSelection: "",
      hourSelection: "",
      confirmationData: { staff: {} },
    }));
    setActiveStep(1);
  };
  return (
    <div className="hour-selection">
      {loading ? (
        <div>
          <p className="text-center text-lg-start">Cargando horarios...</p>
        </div>
      ) : data.length === 0 ? (
        <div>
          <p className="text-center text-lg-start">
            No hay horarios disponibles para esta fecha
          </p>
        </div>
      ) : (
        <div className="hour-selection__schedule">
          {data
            ?.filter((item) => {
              const date = new Date(item.time);
              const minutes = date.getMinutes();
              return minutes === 0 || minutes === 15;
            })
            .map((slot, index) => {
              // Creating a Date object from the time string
              const date = new Date(slot.time);

              // Extracting the hour and minutes. Adjust options as needed.
              const timeString = date.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false, // Use `true` for AM/PM format
              });

              return (
                <button
                  key={index}
                  onClick={() => {
                    handleSelectHour(timeString);
                    setActiveStep(4);
                  }}
                >
                  {timeString}
                  {/* <span>({slot.slotsAvailable} slots)</span> */}
                </button>
              );
            })}
        </div>
      )}
      <div className="selected-service">
        <div>
          <div className="selected-service__date__header">
            <span onClick={handleDeleteService}>&times;</span>
          </div>
          <h6>{appointmentData.productSelection.name}</h6>
          <p>{formatMoney(appointmentData.productSelection.price)}</p>
          <p>
            {" "}
            <img
              src={durationIcon}
              alt={appointmentData.productSelection.nam}
            />
            {appointmentData.productSelection.duration} min
          </p>
        </div>
        {Object.keys(appointmentData.confirmationData.staff).length > 0 ? (
          <div>
            <div className="selected-service__staff__header">
              <span onClick={handleDeleteStaff}>&times;</span>
            </div>
            <h6>Mi cita es con:</h6>
            <p>{appointmentData.confirmationData.staff.name}</p>
          </div>
        ) : (
          <></>
        )}
        <div>
          <div className="selected-service__hour__header">
            <span onClick={handleDeleteDate}>&times;</span>
          </div>
          <h6>Fecha</h6>
          <p>{appointmentData.dateSelection}</p>
        </div>
        {appointmentData.hourSelection !== "" ? (
          <div>
            <div className="selected-service__hour__header">
              <span onClick={handleDeleteHour}>&times;</span>
            </div>
            <h6>Hora:</h6>
            <p>{appointmentData.hourSelection}</p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

function Confirmation({ setActiveStep, setShowBookModal, services }) {
  const { appointmentData, setAppointmentData } = useBookingData();
  const [loading, setLoading] = useState(false);
  const [loadingAddons, setLoadingAddons] = useState(false);
  const [addons, setAddons] = useState([]);

  const [appliedCoupon, setAppliedCoupon] = useState(false);
  const [certificate, setCertificate] = useState(false);

  const [selectedAddons, setSelectedAddons] = useState([]);

  const handleButtonClick = (addon) => {
    setSelectedAddons((prevSelectedAddons) => {
      let updatedAddons;
      if (
        prevSelectedAddons.some(
          (selectedAddon) => selectedAddon.name === addon.name
        )
      ) {
        // Remove the addon from the state if it's already in the array
        updatedAddons = prevSelectedAddons.filter(
          (selectedAddon) => selectedAddon.name !== addon.name
        );
      } else {
        // Add the addon to the state if it's not in the array
        updatedAddons = [...prevSelectedAddons, addon];
      }

      // Update context state
      setAppointmentData((prevData) => ({
        ...prevData,
        selectedAddons: updatedAddons,
      }));

      return updatedAddons;
    });
  };

  const options = {
    mode: "payment",
    amount: 1,
    // amount: parseInt(appointmentData.productSelection.price),
    currency: "mxn",
    // paymentMethodCreation: "manual",
    // Fully customizable with appearance API.
    // appearance: {/*...*/},
  };

  useEffect(() => {
    setLoadingAddons(true);

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/appointment-addons`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setAddons(
          data.filter((addon) =>
            appointmentData.productSelection.addonIDs.includes(addon.id)
          )
        );
        setLoadingAddons(false);
      } catch (error) {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        setLoadingAddons(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (appointmentData.appliedCoupon) {
      setAppliedCoupon(true);
      return () => {};
    }
  }, [appointmentData.appliedCoupon]);

  useEffect(() => {
    if (Object.keys(appointmentData.selectedAddons).length > 0) {
      setSelectedAddons(appointmentData.selectedAddons);
      return () => {};
    }
  }, [appointmentData.selectedAddons]);

  useEffect(() => {
    if (Object.keys(appointmentData.certificate).length > 0) {
      setCertificate(appointmentData.certificate);
      return () => {};
    }
  }, [appointmentData.certificate]);

  const handleDeleteService = () => {
    console.log("delete service");
    setAppointmentData((prevState) => ({
      ...prevState,
      dateSelection: "",
      hourSelection: "",
      productSelection: {},
      confirmationData: {},
      appliedCoupon: false,
      certificate: "",
      selectedAddons: [],
    }));
    setActiveStep(0);
  };

  const handleDeleteDate = () => {
    console.log("delete date");
    setAppointmentData((prevState) => ({
      ...prevState,
      dateSelection: "",
      hourSelection: "",
    }));
    setActiveStep(1);
  };
  const handleDeleteHour = () => {
    console.log("delete date");
    setAppointmentData((prevState) => ({
      ...prevState,
      hourSelection: "",
    }));
    setActiveStep(3);
  };

  const handleDeleteAddon = (id) => {
    setSelectedAddons((prevSelectedAddons) => {
      let updatedAddons;
      if (prevSelectedAddons.some((selectedAddon) => selectedAddon.id === id)) {
        // Remove the addon from the state if it's already in the array
        updatedAddons = prevSelectedAddons.filter(
          (selectedAddon) => selectedAddon.id !== id
        );
      } else {
        // Add the addon to the state if it's not in the array
        updatedAddons = [...prevSelectedAddons, id];
      }
      return updatedAddons;
    });
    setAppointmentData((prevState) => ({
      ...prevState,
      selectedAddons: prevState.selectedAddons.filter(
        (addon) => addon.id !== id
      ),
    }));
  };

  const handleDeleteStaff = () => {
    console.log("delete staff");
    setAppointmentData((prevState) => ({
      ...prevState,
      dateSelection: "",
      hourSelection: "",
      confirmationData: { staff: {} },
    }));
    setActiveStep(1);
  };
  return (
    <div className="confirmation">
      <div>
        {
          <div className="addons">
            <h6>Complementos:</h6>
            <div className="addons__cards">
              {addons?.map((addon, key) => (
                <button
                  key={key}
                  onClick={() => handleButtonClick(addon)}
                  style={{
                    backgroundColor: selectedAddons.some(
                      (selectedAddon) => selectedAddon.name === addon.name
                    )
                      ? "#2d506d"
                      : "white",
                    color: selectedAddons.some(
                      (selectedAddon) => selectedAddon.name === addon.name
                    )
                      ? "white"
                      : "#000",
                  }}
                >
                  {addon.name} <span>{formatMoney(addon.price)}</span>
                </button>
              ))}
            </div>
          </div>
        }
        <h5>Datos personales:</h5>
        {/* <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            loading={loading}
            handleDeleteService={handleDeleteService}
            setLoading={setLoading}
            appointmentData={appointmentData}
            setShowBookModal={setShowBookModal}
            setAppointmentData={setAppointmentData}
          />
        </Elements> */}
        {appliedCoupon === true && certificate.certificate === "AGENDAPRO" ? (
          <CheckoutFormNoStripe
            loading={loading}
            handleDeleteService={handleDeleteService}
            setLoading={setLoading}
            appointmentData={appointmentData}
            setShowBookModal={setShowBookModal}
            setAppointmentData={setAppointmentData}
          />
        ) : (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              loading={loading}
              handleDeleteService={handleDeleteService}
              setLoading={setLoading}
              appointmentData={appointmentData}
              setShowBookModal={setShowBookModal}
              setAppointmentData={setAppointmentData}
            />
          </Elements>
        )}
      </div>
      <div className="selected-service">
        <div>
          <div className="selected-service__date__header">
            <span onClick={handleDeleteService}>&times;</span>
          </div>
          <h6>{appointmentData.productSelection.name}</h6>
          <p>
            {formatMoney(
              certificate.discountAmount === 100
                ? 0
                : appointmentData.productSelection.price
            )}
          </p>
          <p>
            {" "}
            <img
              src={durationIcon}
              alt={appointmentData.productSelection.nam}
            />
            {appointmentData.productSelection.duration} min
          </p>
        </div>
        {Object.keys(appointmentData.confirmationData.staff).length > 0 ? (
          <div>
            <div className="selected-service__staff__header">
              <span onClick={handleDeleteStaff}>&times;</span>
            </div>
            <h6>Mi cita es con:</h6>
            <p>{appointmentData.confirmationData.staff.name}</p>
          </div>
        ) : (
          <></>
        )}
        <div>
          <div className="selected-service__hour__header">
            <span onClick={handleDeleteDate}>&times;</span>
          </div>
          <h6>Fecha</h6>
          <p>{appointmentData.dateSelection}</p>
        </div>
        <div>
          <div className="selected-service__hour__header">
            <span onClick={handleDeleteHour}>&times;</span>
          </div>
          <h6>Hora:</h6>
          <p>{appointmentData.hourSelection}</p>
        </div>
        {appointmentData.selectedAddons.length > 0 ? (
          <div>
            <div className="selected-service__staff__header">
              {/* <span onClick={handleDeleteStaff}>&times;</span> */}
            </div>
            <h6>Complementos seleccionados:</h6>
            {appointmentData.selectedAddons.map((addon, key) => (
              <div className="d-flex justify-content-between" key={key}>
                <p>
                  {addon.name}{" "}
                  <span className="d-inline-block ms-2">
                    {formatMoney(
                      certificate.discountAmount === 100 ? 0 : addon.price
                    )}
                  </span>
                </p>
                <span
                  style={{ fontSize: "1.5rem" }}
                  onClick={() => handleDeleteAddon(addon.id)}
                >
                  &times;
                </span>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

function BookModal({
  showBookModal,
  handleBookModalClose,
  setShowBookModal,
  services,
}) {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    { label: "Nuestros servicios" },
    // { label: "Staff" },
    // { label: "Selecciona fecha" },
    // { label: "Selecciona horario" },
    // { label: "Confirmación y pago" },
  ];
  function getSectionComponent(showBookModal) {
    switch (activeStep) {
      case 0:
        return (
          <AllProducts
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            showBookModal={showBookModal}
          />
        );
      // case 1:
      //   return (
      //     <StaffSelection
      //       setActiveStep={setActiveStep}
      //       showBookModal={showBookModal}
      //     />
      //   );
      // case 2:
      //   return (
      //     <DateSelection
      //       activeStep={activeStep}
      //       setActiveStep={setActiveStep}
      //       showBookModal={showBookModal}
      //     />
      //   );
      // case 3:
      //   return (
      //     <HourSelection
      //       activeStep={activeStep}
      //       setActiveStep={setActiveStep}
      //       showBookModal={showBookModal}
      //     />
      //   );
      // case 4:
      //   return (
      //     <Confirmation
      //       activeStep={activeStep}
      //       setActiveStep={setActiveStep}
      //       showBookModal={showBookModal}
      //       setShowBookModal={setShowBookModal}
      //       services={services}
      //     />
      //   );
      default:
        return null;
    }
  }
  function getStepClasses(step) {
    let cls = "step";
    if (activeStep === step) {
      cls += " active";
    }
  }
  return (
    <Modal
      show={showBookModal}
      onHide={handleBookModalClose}
      className="all-services-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Agenda tu cita</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stepper steps={steps} activeStep={activeStep}>
          {/* {steps.map((label, index) => (
            <div className={getStepClasses(index)} key={index}>
              <div>
                <div className="circle">{index + 1}</div>
              </div>
              <div className="label">{label.label}</div>
              {index < steps.length - 1 && <div className="line"></div>}
            </div>
          ))} */}
        </Stepper>
        {getSectionComponent(showBookModal)}
      </Modal.Body>
    </Modal>
  );
}

// Define a context
// const useBookingData = createContext();

// function AppointmentModal({
//   showBookModal,
//   handleBookModalClose,
//   setShowBookModal,
// }) {
//   const [appointmentData, setAppointmentData] = useState({
//     productSelection: {},
//     dateSelection: "",
//     hourSelection: "",
//     paymentInfo: {},
//     confirmationData: {},
//   });
//   return (
//     <useBookingData.Provider
//       value={{ appointmentData, setAppointmentData }}
//     >
//       <BookModal
//         showBookModal={showBookModal}
//         handleBookModalClose={handleBookModalClose}
//         setShowBookModal={setShowBookModal}
//       />
//     </useBookingData.Provider>
//   );
// }

export default BookModal;
