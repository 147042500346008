import React from "react";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom styles
import "../scss/pages/legal.styles.scss";

function Legal() {
  return (
    <div className="legal">
      <section className="legal__hero"></section>
      <section className="legal__content">
        <h3 className="text-center">Términos y condiciones</h3>
        <Container>
          <p>
            Este contrato describe los términos y condiciones generales (en
            adelante únicamente "TÉRMINOS Y CONDICIONES") aplicables al uso de
            los contenidos, productos y servicios ofrecidos a través del sitio
            cuult.mx de la empresa CUULT STUDIO SA DE CV., CUULT SITIO WEB en
            adelante, del cual es titular (en adelante, ""TITULAR""). Cualquier
            persona que desee acceder o hacer uso del sitio o los servicios que
            en él se ofrecen, podrá hacerlo sujetándose a los presentes TÉRMINOS
            Y CONDICIONES, así como a políticas y principios incorporados al
            presente documento. En todo caso, cualquier persona que no acepte
            los presentes términos y condiciones, deberá abstenerse de utilizar
            el SITIO WEB y/o adquirir los productos y servicios que en su caso
            sean ofrecidos.
          </p>
          <ol>
            <li>DEL OBJETO.</li>
            <p>
              El objeto de los presentes TÉRMINOS Y CONDICIONES es regular el
              acceso y la utilización del SITIO WEB, entendiendo por este
              cualquier tipo de contenido, producto o servicio que se encuentre
              a disposición del público en general dentro del dominio: (página
              web) El TITULAR se reserva la facultad de modificar en cualquier
              momento y sin previo aviso, la presentación, los contenidos, la
              funcionalidad, los productos, los servicios, y la configuración
              que pudiera estar contenida en CUULT SITIO WEB en este sentido, el
              USUARIO reconoce y acepta que en cualquier momento podrá
              interrumpir, desactivar o cancelar cualquiera de los elementos que
              conforman el SITIO WEB o el acceso a los mismos.
            </p>
            <p>
              El acceso al SITIO WEB por parte del USUARIO tiene carácter libre
              y, por regla general es gratuito sin que el USUARIO tenga que
              proporcionar una contraprestación para poder disfrutar de ello,
              salvo en lo referente al costo de la conexión a internet
              suministrada por el proveedor de este tipo de servicios que
              hubiere contratado el mismo USUARIO. El acceso a parte de los
              contenidos y servicios del SITIO WEB podrá realizarse previa
              suscripción o registro previo del USUARIO.
            </p>
            <p>
              El SITIO WEB se encuentra dirigido exclusivamente a personas que
              cuenten con la mayoría de edad (mayores de 18 años); en este
              sentido, declina cualquier responsabilidad por el incumplimiento
              de este requisito.
            </p>
            <p>
              El SITIO WEB está dirigido principalmente a USUARIOS residentes en
              la República Mexicana, por lo cual, no asegura que el SITIO WEB
              cumpla total o parcialmente con la legislación de otros países, de
              forma que, si el USUARIO reside o tiene su domicilio establecido
              en otro país y decide acceder o utilizar el SITIO WEB lo hará bajo
              su propia responsabilidad y deberá asegurarse de que tal acceso y
              navegación cumple con la legislación local que le es aplicable,
              CUULT SITIO WEB o el TITULAR no asumirá ninguna responsabilidad
              que se pueda derivar de dicho acto.
            </p>
            <p>
              Se hace del conocimiento del USUARIO que el TITULAR podrá
              administrar o gestionar el SITIO WEB de manera directa o a través
              de un tercero, lo cual no modifica en ningún sentido lo
              establecido en los presentes TÉRMINOS Y CONDICIONES.
            </p>
            <li>DEL USUARIO.</li>
            <p>
              El acceso o utilización del SITIO WEB, así como de los recursos
              habilitados para interactuar entre los USUARIOS, o entre el
              USUARIO y el TITULAR tales como medios para realizar publicaciones
              o comentarios, confiere la condición de USUARIO del SITIO WEB, por
              lo que quedará sujeto a los presentes TÉRMINOS Y CONDICIONES, así
              como a sus ulteriores modificaciones, sin perjuicio de la
              aplicación de la legislación aplicable, por tanto, se tendrán por
              aceptados desde el momento en el que se accede al SITIO WEB. Dada
              la relevancia de lo anterior, se recomienda al USUARIO revisar las
              actualizaciones que se realicen a los presentes TÉRMINOS Y
              CONDICIONES.
            </p>
            <p>
              Es responsabilidad del USUARIO utilizar el SITIO WEB de acuerdo
              con la forma en la que fue diseñado; en este sentido, queda
              prohibida la utilización de cualquier tipo de software que
              automatice la interacción o descarga de los contenidos o servicios
              proporcionados a través del SITIO WEB. Además, el USUARIO se
              compromete a utilizar la información, contenidos o servicios
              ofrecidos a través del SITIO WEB de manera lícita, sin contravenir
              lo dispuesto en los presentes TÉRMINOS Y CONDICIONES, la moral o
              el orden público, y se abstendrá de realizar cualquier acto que
              pueda suponer una afectación a los derechos de terceros, o
              perjudique de algún modo el funcionamiento del SITIO WEB.
            </p>
            <p>
              Así mismo, el usuario se compromete a proporcionar información
              lícita y veraz en los formularios habilitados en el SITIO WEB, en
              los cuales el usuario tenga que proporcionar ciertos datos o
              información para el acceso a algún contenido, producto o servicio
              ofrecido por el propio SITIO WEB. En todo caso, el USUARIO
              notificará de forma inmediata al TITULAR acerca de cualquier hecho
              que permita suponer el uso indebido de la información registrada
              en dichos formularios, tales como, robo, extravío, o acceso no
              autorizado a cuentas y/o contraseñas, con el fin de proceder a su
              inmediata cancelación.
            </p>
            <p>
              <span>CUULT STUDIO </span>se reserva el derecho de retirar todos
              aquellos comentarios y aportaciones que vulneren la ley, el
              respeto a la dignidad de la persona, que sean discriminatorios,
              atente contra los derechos de tercero o el orden público, o bien,
              que a su juicio no resulten adecuados para su publicación.
            </p>
            <p>
              En cualquier caso, <span>CUULT STUDIO</span> no será responsable
              de las opiniones vertidas por los USUARIOS a través de comentarios
              o publicaciones que estos realicen.
            </p>
            <p>
              El sólo acceso al SITIO WEB no supone el establecimiento de ningún
              tipo de relación entre el TITULAR y el USUARIO.
            </p>
            <p>
              Al tratarse de un SITIO WEB dirigido exclusivamente a personas que
              cuenten con la mayoría de edad, el USUARIO manifiesta ser mayor de
              edad y disponer de la capacidad jurídica necesaria para sujetarse
              a los presentes TÉRMINOS Y CONDICIONES.
            </p>
            <li>DEL ACCESO Y NAVEGACIÓN EN EL SITIO WEB</li>
            <p>
              El TITULAR no garantiza de ningún modo la continuidad y
              disponibilidad de los contenidos, productos o servicios ofrecidos
              a través del SITIO WEB, no obstante, el TITULAR llevará a cabo las
              acciones que de acuerdo con sus posibilidades le permitan mantener
              el buen funcionamiento del SITO WEB, sin que esto suponga alguna
              responsabilidad de parte de <span>CUULT STUDIO.</span>
            </p>
            <p>
              De igual forma <span>CUULT STUDIO</span> no será responsable ni
              garantiza que el contenido o software al que pueda accederse a
              través del SITIO WEB se encuentre libre de errores, software
              malicioso, o que pueda causar algún daño a nivel de software o
              hardware en el equipo a través del cual el USUARIO accede al SITIO
              WEB.
            </p>
            <p>
              El TITULAR tampoco se hace responsable de los daños que pudiesen
              ocasionarse por un uso inadecuado del SITIO WEB. En ningún caso{" "}
              <span>CUULT STUDIO </span>será responsable por las pérdidas, daños
              o perjuicios de cualquier tipo que surjan por el sólo acceso o
              utilización del SITIO WEB.
            </p>
            <li>POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS.</li>
            <p>
              De conformidad con lo establecido en la Ley Federal de Protección
              de Datos Personales en Posesión de Particulares, el TITULAR se
              compromete a adoptar las medidas necesarias que estén a su alcance
              para asegurar la privacidad de los datos personales recabados de
              forma que se garantice su seguridad, se evite su alteración,
              pérdida o tratamiento no autorizado.
            </p>
            <p>
              Además, a efecto de dar cumplimiento a lo establecido en la Ley
              Federal de Protección de Datos Personales en Posesión de
              Particulares, todo dato personal que sea recabado a través del
              SITIO WEB, será tratado de conformidad con los principios de
              licitud, calidad, finalidad, lealtad, y responsabilidad. Todo
              tratamiento de datos personales quedará sujeto al consentimiento
              de su titular. En todo caso, la utilización de datos financieros o
              patrimoniales, requerirán de autorización expresa de sus
              titulares, no obstante, esta podrá darse a través del propio SITIO
              WEB utilizando los mecanismos habilitados para tal efecto, y en
              todo caso se dará la mayor diligencia y cuidado a este tipo de
              datos. Lo mismo ocurrirá en el caso de datos personales sensibles,
              considerando por estos aquellos que debido a una utilización
              indebida puedan dar origen a discriminación o su divulgación
              conlleve un riesgo para el titular.
            </p>
            <p>
              En todo momento se procurará que los datos personales contenidos
              en las bases de datos o archivos que en su caso se utilicen, sean
              pertinentes, correctos y actualizados para los fines para los
              cuales fueron recabados.
            </p>
            <p>
              El SITIO WEB podrá incluir hipervínculos o enlaces que permitan
              acceder a páginas web de terceros distintos de{" "}
              <span>CUULT STUDIO</span>. Los titulares de dichos sitios web
              dispondrán de sus propias políticas de privacidad y protección de
              datos, por lo cual <span>CUULT STUDIO</span> no asume ningún tipo
              de responsabilidad por los datos que son facilitados por el
              USUARIO a través de cualquier sitio web distinto a{" "}
              <span>CUULT STUDIO.</span>
            </p>
            <p>
              <span>CUULT STUDIO</span> se reserva el derecho a modificar su
              Política de Privacidad, de acuerdo con sus necesidades o derivado
              de algún cambio en la legislación. El acceso o utilización del
              SITIO WEB después de dichos cambios, implicará la aceptación de
              estos cambios.
            </p>
            <p>
              Por otra parte, el acceso al SITIO WEB puede implicar la
              utilización de cookies, las cuales, son pequeñas cantidades de
              información que se almacenan en el navegador utilizado por el
              USUARIO. Las cookies facilitan la navegación, la hacen más
              amigable, y no dañan el dispositivo de navegación, para ello,
              pueden recabar información para ingresar al SITIO WEB, almacenar
              las preferencias del USUARIO, así como la interacción que este
              tenga con el SITIO WEB, como por ejemplo: la fecha y hora en la
              que se accede al SITIO WEB, el tiempo que se ha hecho uso de este,
              los sitios visitados antes y después del mismo, el número de
              páginas visitadas, la dirección IP de la cual accede el usuario,
              la frecuencia de visitas, etc.
            </p>
            <p>
              Este tipo de información será utilizada para mejorar el SITIO WEB,
              detectar errores, y posibles necesidades que el USUARIO pueda
              tener, lo anterior a efecto de ofrecer a los USUARIOS servicios y
              contenidos de mejor calidad. En todo caso, la información que se
              recopile será anónima y no se identificará a usuarios
              individuales.
            </p>
            <p>
              En caso de que el USUARIO no desee que se recopile este tipo de
              información deberá deshabilitar, rechazar, restringir y/o eliminar
              el uso de cookies en su navegador de internet.{" "}
            </p>
            <p>
              Los procedimientos para realizar estas acciones pueden diferir de
              un navegador a otro; en consecuencia, se sugiere revisar las
              instrucciones facilitadas por el desarrollador del navegador. En
              el supuesto de que rechace el uso de cookies (total o
              parcialmente) el USUARIO podrá continuar haciendo uso del SITIO
              WEB, aunque podrían quedar deshabilitadas algunas de las funciones
              del mismo.
            </p>
            <p>
              Es posible que en el futuro estas políticas respecto a las cookies
              cambien o se actualicen, por ello es recomendable revisar las
              actualizaciones que se realicen a los presentes TÉRMINOS Y
              CONDICIONES, con objetivo de estar adecuadamente informado sobre
              cómo y para qué utilizamos las cookies que se generan al ingresar
              o hacer uso del SITIO WEB.
            </p>
            <li>POLÍTICA DE ENLACES.</li>
            <p>
              El SITIO WEB puede contener enlaces, contenidos, servicios o
              funciones, de otros sitios de internet pertenecientes y/o
              gestionados por terceros, como por ejemplo imágenes, videos,
              comentarios, motores de búsqueda, etc.
            </p>
            <p>
              La utilización de estos enlaces, contenidos, servicios o
              funciones, tiene por objeto mejorar la experiencia del USUARIO al
              hacer uso del SITIO WEB, sin que pueda considerarse una
              sugerencia, recomendación o invitación para hacer uso de sitios
              externos. <span>CUULT STUDIO</span> en ningún caso revisará o
              controlará el contenido de los sitios externos, de igual forma, no
              hace propios los productos, servicios, contenidos, y cualquier
              otro material existente en los referidos sitios enlazados; por lo
              cual, tampoco se garantizará la disponibilidad, exactitud,
              veracidad, validez o legalidad de los sitios externos a los que se
              pueda tener acceso a través del SITIO WEB. Así mismo, el TITULAR
              no asume ninguna responsabilidad por los daños y perjuicios que
              pudieran producirse por el acceso o uso, de los contenidos,
              productos o servicios gestionados por terceros a los que se pueda
              acceder mediante el SITIO WEB. Es responsabilidad del usuario si
              accede a los enlaces externos que se presentan.
            </p>
            <li>POLÍTICA EN MATERIA DE PROPIEDAD INTELECTUAL E INDUSTRIAL.</li>
            <p>
              <span>CUULT STUDIO</span> por sí o como parte cesionaria, es
              titular de todos los derechos de propiedad intelectual e
              industrial del SITIO WEB, entendiendo por este el código fuente
              que hace posible su funcionamiento así como las imágenes, archivos
              de audio o video, logotipos, marcas, combinaciones de colores,
              estructuras, diseños y demás elementos que lo distinguen. Serán,
              por consiguiente, protegidas por la legislación mexicana en
              materia de propiedad intelectual e industrial, así como por los
              tratados internacionales aplicables. Por consiguiente, queda
              expresamente prohibida la reproducción, distribución, o difusión
              de los contenidos del SITIO WEB, con Fines comerciales, en
              cualquier soporte y por cualquier medio, sin la autorización de{" "}
              <span>CUULT STUDIO.</span>
            </p>
            <p>
              El USUARIO se compromete a respetar los derechos de propiedad
              intelectual e industrial del TITULAR. No obstante, además de poder
              visualizar los elementos del SITIO WEB podrá imprimirlos,
              copiarlos o almacenarlos, siempre y cuando sea exclusivamente para
              su uso estrictamente personal.
            </p>
            <p>
              Por otro lado, el USUARIO, se abstendrá de suprimir, alterar, o
              manipular cualquier elemento, archivo, o contenido, del SITIO WEB,
              y por ningún motivo realizará actos tendientes a vulnerar la
              seguridad, los archivos o bases de datos que se encuentren
              protegidos, ya sea a través de un acceso restringido mediante un
              usuario y contraseña, o porque no cuente con los permisos para
              visualizarlos, editarlos o manipularlos.
            </p>
            <p>
              En caso de que el USUARIO o algún tercero consideren que
              cualquiera de los contenidos del SITIO WEB suponga una violación
              de los derechos de protección de la propiedad industrial o
              intelectual, deberá comunicarlo inmediatamente a{" "}
              <span>CUULT STUDIO</span> a través de los datos de contacto
              disponibles en el propio SITIO WEB.
            </p>
            <li>LEGISLACIÓN Y JURISDICCIÓN APLICABLE.</li>
            <p>
              <span>CUULT STUDIO</span> se reserva la facultad de presentar las
              acciones civiles o penales que considere necesarias por la
              utilización indebida del SITIO WEB, sus contenidos, productos o
              servicios, o por el incumplimiento de los presentes TÉRMINOS Y
              CONDICIONES.
            </p>
            <p>
              La relación entre el USUARIO y <span>CUULT STUDIO</span> se regirá
              por la legislación vigente en México, específicamente en Ciudad de
              México. De surgir cualquier controversia en relación a la
              interpretación y/o a la aplicación de los presentes TÉRMINOS Y
              CONDICIONES, las partes se someterán a la jurisdicción ordinaria
              de los tribunales que correspondan conforme a derecho en el estado
              al que se hace referencia.
            </p>
          </ol>
        </Container>
        <div id="cancellation" className="my-5">
          <h3 className="text-center">Política de Cancelación</h3>
          <Container>
            <p>
              Una vez contratado el servicio, Cuult no podrá realizar
              devoluciones. En caso de necesidad de cambios, se ofrece la opción
              de modificar la cita acordada o generar un vale por el monto
              pagado. En caso de no poder acudir a tu cita, tienes 24 horas para
              poderla reagendar. En caso de no presentarte Cuult podrá tomar tu
              cita como tomada.
            </p>
          </Container>
        </div>
      </section>
    </div>
  );
}

export default Legal;
