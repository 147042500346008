import React from "react";

// Bootstrap components
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";

// Custom styles
import "../scss/pages/questions.styles.scss";

function Questions() {
  return (
    <div className="questions">
      <section className="questions__hero"></section>
      <section className="questions__content">
        <h3 className="text-center wow fadeInUp">Preguntas frecuentes</h3>
        <Container>
          <Accordion
            defaultActiveKey="0"
            className="wow fadeInUp"
            data-wow-delat=".5s"
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                ¿Qué debo informar antes de mi cita en Cuult?
              </Accordion.Header>
              <Accordion.Body>
                Si tienes algún padecimiento relevante como embarazo o dolor
                crónico, por favor infórmanos previamente para adaptar nuestros
                servicios a tus necesidad.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                ¿Necesito hacer una cita previa?
              </Accordion.Header>
              <Accordion.Body>
                Sí, recomendamos reservar cita para garantizar la disponibilidad
                de tu tratamiento preferido en el horario que más te convenga.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                ¿Cuáles son los horarios de Cuult?
              </Accordion.Header>
              <Accordion.Body>
                Estamos abiertos de lunes a viernes de 9:00 a.m. a 6:00 p.m y
                sábados de 9:00 a.m. a 2:00 p.m
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                ¿Cuult ofrece paquetes o promociones?
              </Accordion.Header>
              <Accordion.Body>
                Sí, ofrecemos diversos paquetes y promociones que cambian
                regularmente. Visita nuestra página web o síguenos en redes
                sociales para estar al tanto de las últimas ofertas.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                ¿Qué medidas de higiene y seguridad se aplican en Cuult?
              </Accordion.Header>
              <Accordion.Body>
                En Cuult seguimos estrictos protocolos de higiene y seguridad
                para proteger la salud de nuestros clientes y personal. Esto
                incluye desinfección constante, uso de equipo de protección y
                más.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                ¿Cuult ofrece opciones de regalo?
              </Accordion.Header>
              <Accordion.Body>
                Sí, ofrecemos todo tipo de tarjetas de regalo, las cuales pueden
                usarse para cualquiera de nuestros servicios.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                ¿Cuult tiene estacionamiento disponible?
              </Accordion.Header>
              <Accordion.Body>
                No contamos con estacionamiento propio en Cuult, pero puedes
                estacionar tu vehículo en cualquier calle sin preocuparte por
                parquímetros.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                ¿Puedo cancelar o reprogramar mi cita?
              </Accordion.Header>
              <Accordion.Body>
                Sí, puedes cancelar o reprogramar tu cita contactándonos con al
                menos 24 horas de anticipación. Aquí tienes una lista ampliada
                de preguntas frecuentes que pueden ser útiles para los
                visitantes de la página web de Cuult:
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                ¿Cuult ofrece tratamientos personalizados?
              </Accordion.Header>
              <Accordion.Body>
                Sí, en Cuult personalizamos los tratamientos según las
                necesidades específicas de cada cliente para asegurar la mejor
                experiencia y resultados posibles.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                ¿Cuál es la política de cancelación en Cuult?
              </Accordion.Header>
              <Accordion.Body>
                Si necesitas cancelar tu cita, por favor notificarnos con al
                menos 24 horas de anticipación para poder reasignar tu espacio a
                otro cliente. No ofrecemos reembolsos, pero con gusto podemos
                reagendar tu servicio para otra ocasión. Se permite hacer
                cambios hasta 3 horas antes de la cita programada.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>
                ¿Qué sucede si no asisto a mi cita programada?
              </Accordion.Header>
              <Accordion.Body>
                En caso de no presentarse a la cita sin previo aviso ("No
                Show"), se realizará un cargo del 100% del costo de la sesión.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header>
                ¿Dónde está ubicado Cuult y cómo ingreso al establecimiento?
              </Accordion.Header>
              <Accordion.Body>
                Cuult se encuentra en Monte Líbano 915, detrás de un portón café
                junto a la panadería Odette, en una zona sin parquímetros.
                Cuando llegues, por favor toca el timbre marcado como "CUULT".
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
              <Accordion.Header>
                ¿Qué debo llevar para mi cita en Cuult?
              </Accordion.Header>
              <Accordion.Body>
                Recomendamos venir con ropa cómoda. Si visitas nuestra terraza,
                te sugerimos traer traje de baño o ropa de ejercicio para mayor
                comodidad.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
              <Accordion.Header>
                ¿Cuult ofrece servicios para hombres también?
              </Accordion.Header>
              <Accordion.Body>
                Absolutamente, nuestros servicios están diseñados para ser
                disfrutados por todos.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
              <Accordion.Header>
                ¿Cuult tiene alguna política respecto a la puntualidad?
              </Accordion.Header>
              <Accordion.Body>
                Pedimos a nuestros clientes que lleguen al menos 5 minutos antes
                de su cita programada para asegurar una experiencia completa sin
                prisas.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="15">
              <Accordion.Header>
                ¿Qué formas de pago acepta Cuult?
              </Accordion.Header>
              <Accordion.Body>
                Aceptamos varios métodos de pago, incluyendo efectivo, tarjetas
                de crédito/débito y transferencias bancarias.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="16">
              <Accordion.Header>
                ¿Cuult organiza eventos privados?
              </Accordion.Header>
              <Accordion.Body>
                Sí, organizamos eventos privados y personalizados. Contáctanos
                para más detalles y para planificar tu evento especial.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="17">
              <Accordion.Header>
                ¿Puedo comprar productos de cuidado en Cuult?
              </Accordion.Header>
              <Accordion.Body>
                Sí, ofrecemos una selección de productos para que sigas
                cuidándote en casa y alcances tu mejor versión.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </section>
    </div>
  );
}

export default Questions;
