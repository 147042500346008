import React, { useRef, useEffect, useState } from "react";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
// import Row from "react-bootstrap/Row";
// import Carousel from "react-bootstrap/Carousel";

// Custom styles
import "../scss/pages/home.styles.scss";

// Custom components
import AppointmentModal from "../components/BookModal/BookModal";
import BookModal from "../components/BookModal/BookModal";
import CircuitModal from "../components/CircuitModal/CircuitModal";
import formatMoney from "../helpers/formatMoney";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/ScrollTrigger";
import imagesLoaded from "imagesloaded";

// Context
import { useBookingData } from "../contexts/BookingContext";

// Assets
import starsReviews from "../assets/images/stars-reviews.svg";
import circuit1 from "../assets/images/circuito-sauna.png";
import circuit2 from "../assets/images/circuito-2.png";
import circuit3 from "../assets/images/circuito-3.png";
import circuit4 from "../assets/images/circuito-secondary-1.png";
import circuit5 from "../assets/images/circuito-secondary-2.png";
import circuit6 from "../assets/images/circuito-secondary-3.png";
import circuit7 from "../assets/images/circuito-secondary-4.png";
import circuit8 from "../assets/images/circuito-secondary-5.png";
import secondaryService1 from "../assets/images/secondary-service-1.png";
import secondaryService2 from "../assets/images/secondary-service-2.png";
import secondaryService3 from "../assets/images/secondary-service-3.png";
import aboutCircle from "../assets/images/about-circle.png";
import logoCuultBlue from "../assets/images/logo-cuult--blue.svg";
import stayCool from "../assets/images/stay-cool.svg";
import stayWell from "../assets/images/stay-well.svg";
import stayCoolCircle from "../assets/images/stay-cool.png";
import stayWellCircle from "../assets/images/stay-well.png";
import stayWellCircle1 from "../assets/images/cicuito-test-1.png";
import stayWellCircle2 from "../assets/images/cicuito-test-2.png";
import stayWellCircle3 from "../assets/images/cicuito-test-3.png";
import stayWellCircle4 from "../assets/images/cicuito-test-4.png";
import stayWellCircle5 from "../assets/images/cicuito-test-5.png";
import durationIcon from "../assets/images/duration-icon.svg";
import durationIconBlue from "../assets/images/duration-icon--blue.svg";
import rightArrowSmall from "../assets/images/right-arrow--short-icon--grey.svg";
// import useLocomotiveScroll from "../hooks/useLocomotiveScroll";
import isoLogo from "../assets/images/isoLogo.svg";
import gallery1 from "../assets/images/acupuncture.png";
import gallery2 from "../assets/images/lipomax.png";
import gallery3 from "../assets/images/contrast-therapy.png";
import gallery4 from "../assets/images/high-intensity.png";
import gallery5 from "../assets/images/ballancer-cycle.png";
import gallery6 from "../assets/images/masaje-reductivo.png";
import gallery7 from "../assets/images/oxigen-facial.png";

// Media
import VideoIntro from "../assets/images/video_intro.mp4";
import VideoIntroMobile from "../assets/images/video_intro--mobile.mp4";
import Newsletter from "../components/Newsletter/Newsletter";
import PackagesModal from "../components/PackagesModal/PackagesModal";

gsap.registerPlugin(ScrollTrigger);

const desiredIds = [
  60476910, 61328191, 61328042, 61327784, 61327866, 60476746, 61326687,
];

function Home() {
  const { appointmentData, setAppointmentData } = useBookingData();

  const scrollableDivRef = useRef(null);
  const itemRef = useRef(null);
  const [start, setStart] = useState(true);
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState(null);
  const [groupedServices, setGroupedServices] = useState({});
  const [products, setProducts] = useState(null);
  const [updatedProducts, setUpdatedProducts] = useState([]);

  const [showBookModal, setShowBookModal] = useState(false);
  const handleBookModalClose = () => setShowBookModal(false);
  const handleBookModalOpen = () => setShowBookModal(true);

  const [showCircuitsModal, setShowCircuitsModal] = useState(false);
  const handleCircuitModalClose = () => setShowCircuitsModal(false);
  const handleCircuitModalOpen = () => setShowCircuitsModal(true);

  const [showPackagesModal, setShowPackagesModal] = useState(false);
  const handlePackagesModalClose = () => setShowPackagesModal(false);
  const handlePackagesModalOpen = () => setShowPackagesModal(true);

  const [isDivVisible, setIsDivVisible] = useState(false);
  const [responseData, setResponseData] = useState(false);

  const [showAllServicesMobile, setShowAllServicesMobile] = useState(false);
  const showAllServicesMobileModal = () => {
    setShowAllServicesMobile(true);
  };

  const [showAllServicesModal, setShowAllServicesModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleAllServicesModalClose = () => setShowAllServicesModal(false);
  const handleAllServicesModalShow = () => setShowAllServicesModal(true);

  const firstRef = useRef();
  const secondRef = useRef();
  const thirdRef = useRef();
  const fourthRef = useRef();
  const fifthRef = useRef();
  const sixthRef = useRef();
  const wrapperRef = useRef();
  const sectionsRef = useRef();
  useGSAP();

  // useLocomotiveScroll(start);
  useEffect(() => {
    const ctx = gsap.context((self) => {
      const sections = self.selector(".wrapper section");
      const container = document.querySelector(".wrapper");

      const horizontalTween = gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          pin: true,
          // markers: true,
          scrub: 1,
          end: () => "+=" + document.querySelector(".wrapper").offsetWidth,
        },
      });
      const imageContainers = container.querySelectorAll(
        ".services-scroll__card"
      );

      console.log(imageContainers);

      imageContainers.forEach((container, i) => {
        gsap.to(container.querySelector(".card"), {
          scale: 1.1,
          ease: "none",
          scrollTrigger: {
            id: `test-${i}`,
            trigger: container.querySelector(".card__media"),
            start: "left center",
            end: "right center",
            scrub: true,
            // markers: true,
            invalidateOnRefresh: true,
            containerAnimation: horizontalTween,
            onEnter: () => {
              console.log(`Enter ${i}`);
            },
            onLeave: (self) => {
              console.log(`Complete ${i}`);
              // self.scale(1);
              gsap.to(container.querySelector(".card"), {
                ease: "none",
                scale: 1,
                start: "left center",
                end: "left center",
              });
            },
          },
        });
      });

      // ScrollTrigger.refresh()

      // gsap.to(firstRef.current, {
      //   rotate: 90,
      //   scale: 3,
      //   scrollTrigger: {
      //     trigger: firstRef.current,
      //     containerAnimation: horizontalTween,
      //     markers: true,
      //     start: "center center",
      //   },
      // });

      // gsap.to(secondRef.current, {
      //   rotate: -90,
      //   scale: 3,
      //   scrollTrigger: {
      //     trigger: secondRef.current,
      //     containerAnimation: horizontalTween,
      //     markers: true,
      //     start: "center center",
      //   },
      // });
    }, wrapperRef);
    return () => ctx.revert();
  }, [services, loading]);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/appointment-types`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setServices(data.filter((item) => !item.private));

        const grouped = data
          .filter((item) => !item.private)
          .reduce((acc, service) => {
            if (!acc[service.category]) {
              acc[service.category] = [service];
            } else {
              acc[service.category].push(service);
            }
            return acc;
          }, {});

        setGroupedServices(grouped);
        setLoading(false);
      } catch (error) {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   setLoading(true);
  //   // console.log(`${process.env.REACT_APP_API_URL}/appointment-types`);

  //   const fetchData = async () => {
  //     try {
  //       fetch(`${process.env.REACT_APP_API_URL}/appointment-types`)
  //         .then((response) => {
  //           if (!response.ok) {
  //             setLoading(false);
  //             throw new Error("Network response was not ok");
  //           }
  //           return response.json(); // Parse the response body as JSON
  //         })
  //         .then((data) => {
  //           setServices(data);
  //           // console.log(data);
  //           const grouped = services?.reduce((acc, service) => {
  //             if (!acc[service.category]) {
  //               acc[service.category] = [service];
  //             } else {
  //               acc[service.category].push(service);
  //             }
  //             return acc;
  //           }, {});

  //           // Update the state with the grouped services
  //           setGroupedServices(grouped);
  //           console.log(grouped);
  //           setLoading(false);
  //         });
  //     } catch (error) {
  //       setLoading(false);
  //       console.error(
  //         "There has been a problem with your fetch operation:",
  //         error
  //       );
  //     }
  //   };

  //   fetchData();

  //   // Clean-up function
  //   return () => {
  //     // Optional: Cancel any ongoing fetch request or perform other clean-up tasks
  //   };
  // }, []);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        fetch(`${process.env.REACT_APP_API_URL}/products`)
          .then((response) => {
            if (!response.ok) {
              setLoading(false);
              throw new Error("Network response was not ok");
            }
            return response.json(); // Parse the response body as JSON
          })
          .then((data) => {
            setProducts(data.filter((item) => !item.hidden));
            // console.log(data);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.error(
          "There has been a problem with fetch operation get products",
          error
        );
      }
    };

    fetchData();

    // Clean-up function
    return () => {
      // Optional: Cancel any ongoing fetch request or perform other clean-up tasks
    };
  }, []);

  // useEffect(() => {
  //   // Selecting only images with the 'gallery-image' class
  //   // const images = document.querySelectorAll(".gallery-image");
  //   const images = gsap.utils.toArray(".gallery-image", sectionsRef.current);
  //   // console.log(first);
  //   const showDemo = () => {
  //     document.body.style.overflow = "auto";
  //     document.scrollingElement.scrollTo(0, 0);

  //     sectionsRef.current.forEach((section, index) => {
  //       const w = section.querySelector(".wrapper");
  //       const [x, xEnd] =
  //         index % 2
  //           ? ["100%", (w.scrollWidth - section.offsetWidth) * -1]
  //           : [w.scrollWidth * -1, 0];
  //       gsap.fromTo(
  //         w,
  //         { x },
  //         {
  //           x: xEnd,
  //           scrollTrigger: {
  //             trigger: section,
  //             scrub: 0.5,
  //           },
  //         }
  //       );
  //     });
  //   };

  //   // Make sure you have an equivalent functionality for imagesLoaded
  //   imagesLoaded(images).on("always", showDemo);

  //   // Cleanup function to kill ScrollTriggers on component unmount
  //   return () => {
  //     ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
  //   };
  // }, []);
  useEffect(() => {
    const images = gsap.utils.toArray(".gallery-image", sectionsRef.current);
    // console.log(images);
    // console.log(gsap.utils.toArray(".gallery section"));
    const showDemo = () => {
      document.body.style.overflow = "auto";
      document.scrollingElement.scrollTo(0, 0);

      console.log(images);

      gsap.utils.toArray(".gallery section").forEach((section, index) => {
        const w = section.querySelector(".wrapper");
        const [x, xEnd] =
          index % 2
            ? ["100%", (w.scrollWidth - section.offsetWidth) * -1]
            : [w.scrollWidth * -1, 0];
        gsap.fromTo(
          w,
          { x },
          {
            x: xEnd,
            scrollTrigger: {
              trigger: section,
              scrub: 0.5,
            },
          }
        );
      });
    };

    // Make sure imagesLoaded is correctly imported or available in your project
    imagesLoaded(images, showDemo);

    // Optional: Cleanup function if needed
    return () => {
      // Cleanup or reset animations
    };
  }, [services, loading]);
  // useEffect(() => {
  //   const scrollableDiv = scrollableDivRef.current;
  //   let lastScrollTop = 0;

  //   const onScroll = () => {
  //     let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  //     if (scrollTop > lastScrollTop) {
  //       // Scrolling down
  //       scrollableDiv.scrollLeft += 50; // Adjust as needed
  //     } else {
  //       // Scrolling up
  //       scrollableDiv.scrollLeft -= 50; // Adjust as needed
  //     }
  //     lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
  //   };

  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach(
  //         (entry) => {
  //           if (entry.isIntersecting) {
  //             window.addEventListener("scroll", onScroll);
  //           } else {
  //             window.removeEventListener("scroll", onScroll);
  //           }
  //         },
  //         {
  //           rootMargin: "20%", // Adjust this value based on trial and error
  //         }
  //       );
  //     },
  //     { threshold: 0.9 }
  //   ); // Adjust threshold as needed

  //   observer.observe(scrollableDiv);

  //   return () => {
  //     observer.disconnect();
  //     window.removeEventListener("scroll", onScroll);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (!isDivVisible && itemRef.current && scrollableDivRef.current) {
  //     // Scroll the item to the right
  //     const duration = 1000; // milliseconds
  //     const targetScroll = itemRef.current.scrollWidth;
  //     const step = 10; // Adjust this value to control scroll speed
  //     let currentScroll = itemRef.current.scrollLeft;

  //     const scrollInterval = setInterval(() => {
  //       if (currentScroll < targetScroll) {
  //         currentScroll += step;
  //         if (currentScroll >= targetScroll) {
  //           clearInterval(scrollInterval);
  //         }
  //         itemRef.current.scrollLeft = currentScroll;
  //       }
  //     }, duration / (targetScroll / step));
  //   }
  // }, [isDivVisible]);

  // useEffect(() => {
  //   const stickySections = [...document.querySelectorAll(".sticky")];
  //   const images = []; // You need to define images array, as it's used in the forEach loop

  //   // services.data.forEach((img) => {
  //   //   stickySections.forEach((section) => {
  //   //     const div = document.createElement("div");
  //   //     div.classList.add("item"); // Add any necessary classes or styles
  //   //     section.querySelector(".scroll_section").appendChild(div);
  //   //   });
  //   // });

  //   // Directly select the target .scroll_section to append the cardHTML
  //   // Assuming there's only one .sticky section you're targeting
  //   const scrollSection = document.querySelector(".sticky .scroll_section");
  //   if (scrollSection) {
  //     scrollSection.innerHTML = "";
  //     services.data.forEach((service) => {
  //       const cardHTML = `
  //   <div class="card">
  //     <div class="card__media">
  //       <img src="${service.imgUrl}" alt="${service.serviceName}">
  //     </div>
  //     <div class="card__body">
  //       <h3>${service.serviceName}</h3>
  //       <p>${service.serviceDescription}</p>
  //       <div class="card__footer">
  //         <p>Precio: ${formatMoney(service.servicePrice)}</p>
  //       </div>
  //     </div>
  //   </div>
  // `;
  //       scrollSection.innerHTML += cardHTML;
  //     });
  //   }

  //   const transform = (section) => {
  //     const offsetTop = section.parentElement.offsetTop;
  //     const scrollSection = section.querySelector(".scroll_section");
  //     let percentage =
  //       ((window.scrollY - offsetTop) / window.innerHeight) * 100;
  //     percentage = percentage < 0 ? 0 : percentage > 400 ? 400 : percentage;
  //     scrollSection.style.transform = `translate3d(${-percentage}vw, 0, 0)`;
  //   };

  //   const handleScroll = () => {
  //     stickySections.forEach((section) => {
  //       transform(section);
  //     });
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // useEffect(() => {
  //   const stickySections = [...document.querySelectorAll(".sticky")];
  //   setResponseData(services.data);
  //   const scrollSection = document.querySelector(".sticky .scroll_section");
  //   if (scrollSection && responseData.length > 0) {
  //     scrollSection.innerHTML = "";
  //     responseData.forEach((service) => {
  //       const cardHTML = `
  //       <div class="card">
  //         <div class="card__media">
  //           <img src="${service.imgUrl}" alt="${service.serviceName}">
  //         </div>
  //         <div class="card__body">
  //           <h3>${service.serviceName}</h3>
  //           <p>${service.serviceDescription}</p>
  //           <div class="card__footer">
  //             <p>Precio: ${formatMoney(service.servicePrice)}</p>
  //           </div>

  //         </div>
  //       </div>
  //     `;
  //       scrollSection.innerHTML += cardHTML;
  //     });
  //   }

  //   const transform = (section) => {
  //     const offsetTop = section.parentElement.offsetTop;
  //     const scrollSection = section.querySelector(".scroll_section");
  //     let percentage =
  //       ((window.scrollY - offsetTop) / window.innerHeight) * 100;
  //     percentage = percentage < 0 ? 0 : percentage > 400 ? 400 : percentage;
  //     scrollSection.style.transform = `translate3d(${-percentage}vw, 0, 0)`;
  //   };

  //   const handleScroll = () => {
  //     stickySections.forEach((section) => {
  //       transform(section);
  //     });
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [responseData]);

  function calculateTotalDurations(services, products) {
    return products
      ?.filter(
        (product) =>
          !product.name.includes("(PAQ") && !product.name.includes("MASAJE")
      )
      .map((product) => {
        // Calculate the sum of durations for the appointmentTypeIDs in this product
        const totalDuration = product.appointmentTypeIDs.reduce((acc, id) => {
          const service = services.find((service) => service.id === id);
          return acc + (service ? service.duration : 0);
        }, 0);

        // Return a new object for the product with the calculated total duration
        return { ...product, totalDuration };
      });
  }

  // const updatedProducts2 = calculateTotalDurations(services, products);
  // // console.log(updatedProducts);
  // setUpdatedProducts(updatedProducts2)
  useEffect(() => {
    if (services?.length > 0 && products?.length > 0) {
      const updated = calculateTotalDurations(services, products);
      setUpdatedProducts(updated);
    }
  }, [services, products]);

  return (
    <>
      {/* <div className="temp"></div> */}
      <div className="first-block"></div>
      <BookModal
        showBookModal={showBookModal}
        setShowBookModal={setShowBookModal}
        handleBookModalClose={handleBookModalClose}
        services={services}
      />
      <CircuitModal
        showCircuitsModal={showCircuitsModal}
        handleCircuitModalClose={handleCircuitModalClose}
        services={services}
      />
      <PackagesModal
        showPackagesModal={showPackagesModal}
        handlePackagesModalClose={handlePackagesModalClose}
        products={products?.filter(
          (product) =>
            product.name.includes("(PAQ") || product.name.includes("MASAJE")
        )}
        services={services}
      />
      <section className="home__hero wow fadeInUp">
        {/* <section className="home__hero wow fadeInUp" data-wow-delay="2.6s"> */}
        <video
          autoPlay
          loop
          muted
          playsInline
          className="video d-none d-lg-block"
        >
          <source src={VideoIntro} type="video/mp4" />
        </video>
        <video
          autoPlay
          loop
          muted
          playsInline
          className="video d-block d-lg-none"
        >
          <source src={VideoIntroMobile} type="video/mp4" />
        </video>
        <button
          className="wow fadeInUp"
          onClick={handleBookModalOpen}
          data-wow-delay="2s"
        >
          Reserva ahora
        </button>
        <button
          className="wow fadeInUp"
          onClick={handlePackagesModalOpen}
          data-wow-delay="2s"
        >
          Paquetes
        </button>
        {/* <Container className="text-center">
          <h1>It's time</h1>
          <button href="" onClick={handleBookModalOpen}>
            Reserva ahora
          </button>
        </Container> */}
      </section>
      <section className="about">
        <Container className="about__container">
          <div className="about__content">
            <h2 className="wow fadeInLeft">
              {/* <h2 className="wow fadeInLeft" data-wow-delay="2.8s"> */}
              {/* Toma un tiempo para nutrir tu cuerpo y alma con nuestros
              tratamientos los cuales están diseñados para lograr un equilibrio
              en ti a través de una expericiencia revitalizadora. */}
              En Cuult tenemos todo lo que necesitas para optimizar la
              recuperación física, mental, emocional y espiritual. Nuestras
              soluciones dan un boost para poner a a tu cuerpo en su estado
              óptimo, dejándolo hacer su magia y poder sanar. <br /> Te
              garantizamos que cada visita te hará sentirte como en casa, más
              feliz y relajado que nunca.
            </h2>
          </div>
          <div className="about__media wow fadeInRight">
            {/* <div className="about__media wow fadeInRight" data-wow-delay="2.8s"> */}
            {/* <img src={isoLogo} alt="Cuult" /> */}
            <img src={aboutCircle} alt="Cuult" />
          </div>
          {/* <div className="about__reviews">
            <Carousel indicators={false}>
              <Carousel.Item>
                <Carousel.Caption>
                  <p>ELENA M. 21/01/24</p>
                  <h6>
                    “Quisquam est, qui dolorem ipsum quia dolor sit amet,
                    consectetur, adipisci velit, sed quia non numquam eius modi
                    tempora incidunt ut labore et dolore magnam aliquam quaerat”
                  </h6>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <Carousel.Caption>
                  <p>ELENA M. 21/01/24</p>
                  <h6>
                    “Quisquam est, qui dolorem ipsum quia dolor sit amet,
                    consectetur, adipisci velit, sed quia non numquam eius modi
                    tempora incidunt ut labore et dolore magnam aliquam quaerat”
                  </h6>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <Carousel.Caption>
                  <p>ELENA M. 21/01/24</p>
                  <h6>
                    “Quisquam est, qui dolorem ipsum quia dolor sit amet,
                    consectetur, adipisci velit, sed quia non numquam eius modi
                    tempora incidunt ut labore et dolore magnam aliquam quaerat”
                  </h6>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
            <div className="about__reviews__total">
              <img src={starsReviews} alt="CUULT" />
              <p>210 reseñas verificadas</p>
            </div>
          </div> */}
        </Container>
      </section>
      <section id="services" className="services">
        <Container>
          <h3>Servicios</h3>
          <p className="all-services" onClick={handleAllServicesModalShow}>
            ver todos
          </p>
          <div className="wrapper" ref={wrapperRef}>
            {/* {!loading && services?.length > 0 ? (
              <>
                <section className="first services-scroll__card">
                  <div className="card" ref={firstRef}>
                    <div className="card__media">
                      <img
                        src={services[0]?.image}
                        alt="${services[0]?.name}"
                      />
                      <button
                        onClick={() => {
                          setAppointmentData((prevState) => ({
                            ...prevState,
                            productSelection: services[0],
                          }));
                          setShowBookModal(true);
                        }}
                      >
                        reservar
                      </button>
                    </div>
                    <div className="card__body">
                      <h3>{services[0]?.name}</h3>
                      <p>
                        {services[0]?.description
                          .split("\n")
                          .filter((line) => line.trim() !== "")
                          .map((line, index) => (
                            <span key={index}>
                              {index > 0 ? "+ " : ""}
                              {line}
                              <br />
                            </span>
                          ))}
                      </p>
                      <div className="card__footer">
                        <div>
                          <img src={durationIconBlue} alt="" />
                          <span>{services[0]?.duration} min</span>
                        </div>
                        <p>Precio: {formatMoney(services[0]?.price)}</p>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="second services-scroll__card">
                  <div className="card" ref={secondRef}>
                    <div className="card__media">
                      <img
                        src={services[1]?.image}
                        alt="${services[0]?.name}"
                      />
                      <button
                        onClick={() => {
                          setAppointmentData((prevState) => ({
                            ...prevState,
                            productSelection: services[1],
                          }));
                          setShowBookModal(true);
                        }}
                      >
                        reservar
                      </button>
                    </div>
                    <div className="card__body">
                      <h3>{services[1]?.name}</h3>
                      {services[1]?.description
                        .split("\n")
                        .filter((line) => line.trim() !== "")
                        .map((line, index) => (
                          <span key={index}>
                            {index > 0 ? "+ " : ""}
                            {line}
                            <br />
                          </span>
                        ))}
                      <div className="card__footer">
                        <div>
                          <img src={durationIconBlue} alt="" />
                          <span>{services[1]?.duration} min</span>
                        </div>
                        <p>Precio: {formatMoney(services[1]?.price)}</p>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="third services-scroll__card">
                  <div className="card" ref={thirdRef}>
                    <div className="card__media">
                      <img src={services[2]?.image} alt="${service[0].name}" />
                      <button
                        onClick={() => {
                          setAppointmentData((prevState) => ({
                            ...prevState,
                            productSelection: services[2],
                          }));
                          setShowBookModal(true);
                        }}
                      >
                        reservar
                      </button>
                    </div>
                    <div className="card__body">
                      <h3>{services[2]?.name}</h3>
                      {services[2]?.description
                        .split("\n")
                        .filter((line) => line.trim() !== "")
                        .map((line, index) => (
                          <span key={index}>
                            {index > 0 ? "+ " : ""}
                            {line}
                            <br />
                          </span>
                        ))}
                      <div className="card__footer">
                        <div>
                          <img src={durationIconBlue} alt="" />
                          <span>{services[2]?.duration} min</span>
                        </div>
                        <p>Precio: {formatMoney(services[2]?.price)}</p>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="fourth services-scroll__card">
                  <div className="card" ref={secondRef}>
                    <div className="card__media">
                      <img src={services[3].image} alt="${service.name}" />
                      <button
                        onClick={() => {
                          setAppointmentData((prevState) => ({
                            ...prevState,
                            productSelection: services[3],
                          }));
                          setShowBookModal(true);
                        }}
                      >
                        reservar
                      </button>
                    </div>
                    <div className="card__body">
                      <h3>{services[3].name}</h3>
                      {services[3]?.description
                        .split("\n")
                        .filter((line) => line.trim() !== "")
                        .map((line, index) => (
                          <span key={index}>
                            {index > 0 ? "+ " : ""}
                            {line}
                            <br />
                          </span>
                        ))}
                      <div className="card__footer">
                        <div>
                          <img src={durationIconBlue} alt="" />
                          <span>{services[3]?.duration} min</span>
                        </div>
                        <p>Precio: {formatMoney(services[3].price)}</p>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="fifth services-scroll__card">
                  <div className="card" ref={secondRef}>
                    <div className="card__media">
                      <img src={services[4]?.image} alt="${service[0].name}" />
                      <button
                        onClick={() => {
                          setAppointmentData((prevState) => ({
                            ...prevState,
                            productSelection: services[4],
                          }));
                          setShowBookModal(true);
                        }}
                      >
                        reservar
                      </button>
                    </div>
                    <div className="card__body">
                      <h3>{services[4]?.name}</h3>
                      {services[4]?.description
                        .split("\n")
                        .filter((line) => line.trim() !== "")
                        .map((line, index) => (
                          <span key={index}>
                            {index > 0 ? "+ " : ""}
                            {line}
                            <br />
                          </span>
                        ))}
                      <div className="card__footer">
                        <div>
                          <img src={durationIconBlue} alt="" />
                          <span>{services[4]?.duration} min</span>
                        </div>
                        <p>Precio: {formatMoney(services[4]?.price)}</p>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="sixth services-scroll__card">
                  <div className="card" ref={secondRef}>
                    <div className="card__media">
                      <img
                        src={services[5]?.image}
                        alt="${service.serviceName}"
                      />
                      <button
                        onClick={() => {
                          setAppointmentData((prevState) => ({
                            ...prevState,
                            productSelection: services[5],
                          }));
                          setShowBookModal(true);
                        }}
                      >
                        reservar
                      </button>
                    </div>
                    <div className="card__body">
                      <h3>{services[5]?.name}</h3>
                      {services[5]?.description
                        .split("\n")
                        .filter((line) => line.trim() !== "")
                        .map((line, index) => (
                          <span key={index}>
                            {index > 0 ? "+ " : ""}
                            {line}
                            <br />
                          </span>
                        ))}
                      <div className="card__footer">
                        <div>
                          <img src={durationIconBlue} alt="" />
                          <span>{services[5]?.duration} min</span>
                        </div>
                        <p>Precio: {formatMoney(services[5]?.price)}</p>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            ) : (
              <></>
            )} */}
            {!loading && services?.length > 0 ? (
              <>
                {/* {console.log(
                  services.filter((service) => desiredIds.includes(service.id))
                )} */}
                {services
                  .filter((service) => desiredIds.includes(service.id))
                  .map((finalService, key) => (
                    <section className="first services-scroll__card">
                      <div className="card" ref={firstRef}>
                        <div className="card__media">
                          <img
                            src={finalService?.image}
                            alt={finalService?.name}
                          />
                          <button
                            onClick={() => {
                              // setAppointmentData((prevState) => ({
                              //   ...prevState,
                              //   productSelection: finalService,
                              // }));
                              // setShowBookModal(true);
                              window.location.replace(
                                `https://cuultcenter.as.me/?appointmentType=${finalService.id},`
                              );
                            }}
                          >
                            reservar
                          </button>
                        </div>
                        <div className="card__body">
                          <h3>{finalService?.name}</h3>
                          <p>
                            {finalService?.description
                              .split("\n")
                              .filter((line) => line.trim() !== "")
                              .map((line, index) => (
                                <span key={index}>
                                  {index > 0 ? "+ " : ""}
                                  {line}
                                  <br />
                                </span>
                              ))}
                          </p>
                          <div className="card__footer">
                            <div>
                              <img src={durationIconBlue} alt="" />
                              <span>{finalService?.duration} min</span>
                            </div>
                            <p>Precio: {formatMoney(finalService?.price)}</p>
                          </div>
                        </div>
                      </div>
                    </section>
                  ))}
              </>
            ) : (
              <></>
            )}
          </div>
        </Container>
      </section>
      <section className="services__secondary">
        <Container className="d-flex flex-column align-items-center">
          <div
            className="services__secondary__links services__secondary__links--1 wow fadeInUp"
            data-wow-delay=".3s"
          >
            {/* <h2>Skin treatments</h2> */}
            <img src={logoCuultBlue} alt="" />
            {/* <div className="services__secondary__links__image services__secondary__links__image--1">
              <img src={secondaryService1} alt="" srcset="" />
            </div> */}
          </div>
          <div
            className="services__secondary__links services__secondary__links--2 wow fadeInUp"
            data-wow-delay=".6s"
          >
            {/* <h2>Skin treatments 2</h2> */}
            <img src={stayCool} alt="" />
            <div className="services__secondary__links__image services__secondary__links__image--2">
              <img src={stayCoolCircle} alt="" srcset="" />
            </div>
          </div>
          <div
            className="services__secondary__links services__secondary__links--3 wow fadeInUp"
            data-wow-delay=".9s"
          >
            {/* <h2>Skin treatments 3</h2> */}
            <img src={stayWell} alt="" />
            <div className="services__secondary__links__image services__secondary__links__image--3">
              <img src={stayWellCircle} alt="" srcset="" />
            </div>
          </div>
        </Container>
      </section>
      <section id="circuits" className="circuits">
        <Container fluid>
          <h3 className="wow fadeInDown">Circuitos</h3>
          <div className="circuits__cards wow fadeInUp" data-wow-delay=".3s">
            {
              // products
              //   ?.filter(
              //     (product) =>
              //       !product.name.includes("(PAQ") &&
              //       !product.name.includes("MASAJE")
              //   )
              updatedProducts.map((product, key) => (
                <div className={`circuits__card circuits__card--${key}`}>
                  <div className="overlay">
                    <a
                      href={`https://app.acuityscheduling.com/catalog.php?owner=31533012&action=addCart&clear=1&id=${product.id}`}
                    >
                      reservar
                    </a>
                    {/* <button
                    onClick={() => {
                      setAppointmentData((prevState) => ({
                        ...prevState,
                        productSelection: product,
                      }));
                      setShowCircuitsModal(true);

                    }}
                  >
                    reservar
                  </button> */}
                    <div className="circuits__card__body">
                      <h6>{product.name}</h6>
                      <p>{product.description.split("\n")[0]}</p>
                      <div className="circuits__card__body__footer">
                        <p>
                          <img src={durationIconBlue} alt="" />
                          {product.totalDuration} min.
                        </p>
                        <p>{formatMoney(product.price)}</p>
                      </div>
                    </div>
                  </div>
                  <h5>{product.name}</h5>
                  {/* <span>{product.description}</span> */}
                  {/* <div className="circuits__card__media">
                <img src={circuit4} alt="" srcset="" />
              </div>
              <div className="circuits__card__body">
                <h6>Circuito Sauna</h6>
                <p>Sauna + Cold Plunge</p>
                <div className="circuits__card__body__footer">
                  <p>{formatMoney(1200)}</p>
                </div>
              </div> */}
                  {/* <div> */}
                  {/* <img
                src={stayWellCircle1}
                alt=""
                srcset=""
                className="wow fadeInUp"
              /> */}
                  {/* </div> */}
                </div>
              ))
            }
            {/* <div className="circuits__card__media">
                <img src={circuit4} alt="" srcset="" />
              </div>
              <div className="circuits__card__body">
                <h6>Circuito Sauna</h6>
                <p>Sauna + Cold Plunge</p>
                <div className="circuits__card__body__footer">
                  <p>{formatMoney(1200)}</p>
                </div>
              </div> */}
            {/* <div> */}
            {/* <img
                src={stayWellCircle1}
                alt=""
                srcset=""
                className="wow fadeInUp"
              /> */}
            {/* <div className="circuits__card circuits__card--1">
              <div className="overlay">
                <button>reservar</button>
                <div className="circuits__card__body">
                  <h6>Circuito Sauna</h6>
                  <p>Sauna + Cold Plunge</p>
                  <div className="circuits__card__body__footer">
                    <p>
                      <img src={durationIcon} alt="" />
                      90 min
                    </p>
                    <p>{formatMoney(1200)}</p>
                  </div>
                </div>
              </div>
              <h5>Circuit Name</h5>
              <span>Sauna + Cold Plunge</span>
            </div>
            <div className="circuits__card circuits__card--2">
              <div className="overlay">
                <button>reservar</button>
                <div className="circuits__card__body">
                  <h6>Circuito Sauna</h6>
                  <p>Sauna + Cold Plunge</p>
                  <div className="circuits__card__body__footer">
                    <p>
                      <img src={durationIcon} alt="" />
                      90 min
                    </p>
                    <p>{formatMoney(1200)}</p>
                  </div>
                </div>
              </div>
              <h5>Circuit Name</h5>
              <span>Sauna + Cold Plunge</span>
            </div>
            <div className="circuits__card circuits__card--3">
              <div className="overlay">
                <button>reservar</button>
                <div className="circuits__card__body">
                  <h6>Circuito Sauna</h6>
                  <p>Sauna + Cold Plunge</p>
                  <div className="circuits__card__body__footer">
                    <p>
                      <img src={durationIcon} alt="" />
                      90 min
                    </p>
                    <p>{formatMoney(1200)}</p>
                  </div>
                </div>
              </div>
              <h5>Circuit Name</h5>
              <span>Sauna + Cold Plunge</span>
            </div>
            <div className="circuits__card circuits__card--4">
              <div className="overlay">
                <button>reservar</button>
                <div className="circuits__card__body">
                  <h6>Circuito Sauna</h6>
                  <p>Sauna + Cold Plunge</p>
                  <div className="circuits__card__body__footer">
                    <p>
                      <img src={durationIcon} alt="" />
                      90 min
                    </p>
                    <p>{formatMoney(1200)}</p>
                  </div>
                </div>
              </div>
              <h5>Circuit Name</h5>
              <span>Sauna + Cold Plunge</span>
            </div>
            <div className="circuits__card circuits__card--5">
              <div className="overlay">
                <button>reservar</button>
                <div className="circuits__card__body">
                  <h6>Circuito Sauna</h6>
                  <p>Sauna + Cold Plunge</p>
                  <div className="circuits__card__body__footer">
                    <p>
                      <img src={durationIcon} alt="" />
                      90 min
                    </p>
                    <p>{formatMoney(1200)}</p>
                  </div>
                </div>
              </div>
              <h5>Circuit Name</h5>
              <span>Sauna + Cold Plunge</span>
            </div> */}
          </div>
        </Container>
      </section>
      {/* <section id="q-a" className="q-a wow fadeInUp">
        <Container className="q-a__container wow fadeInUp" data-wow-delay=".5s">
          <h3 className="wow fadeInUp" data-wow-delay="1s">
            preguntas frecuentes
          </h3>
          <Accordion
            defaultActiveKey="0"
            className="wow fadeInUp"
            data-wow-delay="1.2s"
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                ¿Qué debo informar antes de mi cita en Cuult?
              </Accordion.Header>
              <Accordion.Body>
                Si tienes algún padecimiento relevante como embarazo o dolor
                crónico, por favor infórmanos previamente para adaptar nuestros
                servicios a tus necesidad.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                ¿Necesito hacer una cita previa?
              </Accordion.Header>
              <Accordion.Body>
                Sí, recomendamos reservar cita para garantizar la disponibilidad
                de tu tratamiento preferido en el horario que más te convenga.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                ¿Cuáles son los horarios de Cuult?
              </Accordion.Header>
              <Accordion.Body>
                Estamos abiertos de lunes a viernes de 9:00 a.m. a 6:00 p.m y
                sábados de 9:00 a.m. a 2:00 p.m
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                ¿Cuult ofrece paquetes o promociones?
              </Accordion.Header>
              <Accordion.Body>
                Sí, ofrecemos diversos paquetes y promociones que cambian
                regularmente. Visita nuestra página web o síguenos en redes
                sociales para estar al tanto de las últimas ofertas.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                ¿Qué medidas de higiene y seguridad se aplican en Cuult?
              </Accordion.Header>
              <Accordion.Body>
                En Cuult seguimos estrictos protocolos de higiene y seguridad
                para proteger la salud de nuestros clientes y personal. Esto
                incluye desinfección constante, uso de equipo de protección y
                más.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                ¿Cuult ofrece opciones de regalo?
              </Accordion.Header>
              <Accordion.Body>
                Sí, ofrecemos todo tipo de tarjetas de regalo, las cuales pueden
                usarse para cualquiera de nuestros servicios.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                ¿Cuult tiene estacionamiento disponible?
              </Accordion.Header>
              <Accordion.Body>
                No contamos con estacionamiento propio en Cuult, pero puedes
                estacionar tu vehículo en cualquier calle sin preocuparte por
                parquímetros.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                ¿Puedo cancelar o reprogramar mi cita?
              </Accordion.Header>
              <Accordion.Body>
                Sí, puedes cancelar o reprogramar tu cita contactándonos con al
                menos 24 horas de anticipación. Aquí tienes una lista ampliada
                de preguntas frecuentes que pueden ser útiles para los
                visitantes de la página web de Cuult:
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                ¿Cuult ofrece tratamientos personalizados?
              </Accordion.Header>
              <Accordion.Body>
                Sí, en Cuult personalizamos los tratamientos según las
                necesidades específicas de cada cliente para asegurar la mejor
                experiencia y resultados posibles.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                ¿Cuál es la política de cancelación en Cuult?
              </Accordion.Header>
              <Accordion.Body>
                Si necesitas cancelar tu cita, por favor notificarnos con al
                menos 24 horas de anticipación para poder reasignar tu espacio a
                otro cliente. No ofrecemos reembolsos, pero con gusto podemos
                reagendar tu servicio para otra ocasión. Se permite hacer
                cambios hasta 3 horas antes de la cita programada.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>
                ¿Qué sucede si no asisto a mi cita programada?
              </Accordion.Header>
              <Accordion.Body>
                En caso de no presentarse a la cita sin previo aviso ("No
                Show"), se realizará un cargo del 100% del costo de la sesión.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header>
                ¿Dónde está ubicado Cuult y cómo ingreso al establecimiento?
              </Accordion.Header>
              <Accordion.Body>
                Cuult se encuentra en Monte Líbano 915, detrás de un portón café
                junto a la panadería Odette, en una zona sin parquímetros.
                Cuando llegues, por favor toca el timbre marcado como "CUULT".
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
              <Accordion.Header>
                ¿Qué debo llevar para mi cita en Cuult?
              </Accordion.Header>
              <Accordion.Body>
                Recomendamos venir con ropa cómoda. Si visitas nuestra terraza,
                te sugerimos traer traje de baño o ropa de ejercicio para mayor
                comodidad.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
              <Accordion.Header>
                ¿Cuult ofrece servicios para hombres también?
              </Accordion.Header>
              <Accordion.Body>
                Absolutamente, nuestros servicios están diseñados para ser
                disfrutados por todos.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
              <Accordion.Header>
                ¿Cuult tiene alguna política respecto a la puntualidad?
              </Accordion.Header>
              <Accordion.Body>
                Pedimos a nuestros clientes que lleguen al menos 5 minutos antes
                de su cita programada para asegurar una experiencia completa sin
                prisas.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="15">
              <Accordion.Header>
                ¿Qué formas de pago acepta Cuult?
              </Accordion.Header>
              <Accordion.Body>
                Aceptamos varios métodos de pago, incluyendo efectivo, tarjetas
                de crédito/débito y transferencias bancarias.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="16">
              <Accordion.Header>
                ¿Cuult organiza eventos privados?
              </Accordion.Header>
              <Accordion.Body>
                Sí, organizamos eventos privados y personalizados. Contáctanos
                para más detalles y para planificar tu evento especial.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="17">
              <Accordion.Header>
                ¿Puedo comprar productos de cuidado en Cuult?
              </Accordion.Header>
              <Accordion.Body>
                Sí, ofrecemos una selección de productos para que sigas
                cuidándote en casa y alcances tu mejor versión.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </section> */}
      <section className="newsletter__banner">
        <div className="newsletter__banner__content">
          <h5>join the cuult</h5>
          <p>
            Al suscribirte, tendrás acceso a promociones y descuentos exclusivos
            en nuestros servicios. <br /> ¡Inscríbete y aprovecha las ofertas
            antes que nadie! Puedes darte de baja en cualquier momento.
          </p>
          <Newsletter />
        </div>
      </section>
      <div className="gallery" ref={sectionsRef}>
        <div className="demo-wrapper">
          {/* <header className="df aic jcc">
            <div>
              <h1>ScrollTrigger</h1>
              <h2>demo</h2>
            </div>
          </header> */}
          <section className="demo-text">
            <div className="wrapper text">STAYCOOLSTAYWELL</div>
          </section>
          <section className="demo-gallery">
            <ul className="wrapper">
              <li>
                <img
                  height="317"
                  src={gallery1}
                  width="450"
                  className="gallery-image"
                />
              </li>
              <li>
                <img
                  height="317"
                  src={gallery2}
                  width="450"
                  className="gallery-image"
                />
              </li>
              <li>
                <img
                  height="317"
                  src={gallery3}
                  width="450"
                  className="gallery-image"
                />
              </li>
            </ul>
          </section>
          <section className="demo-gallery">
            <ul className="wrapper">
              <li>
                <img
                  height="317"
                  src={gallery4}
                  width="450"
                  className="gallery-image"
                />
              </li>
              <li>
                <img
                  height="317"
                  src={gallery5}
                  width="450"
                  className="gallery-image"
                />
              </li>
              <li>
                <img
                  height="317"
                  src={gallery6}
                  width="450"
                  className="gallery-image"
                />
              </li>
              <li>
                <img
                  height="317"
                  src={gallery7}
                  width="450"
                  className="gallery-image"
                />
              </li>
            </ul>
          </section>
          {/* <section className="demo-gallery">
            <ul className="wrapper">
              <li>
                <img
                  height="317"
                  src="https://source.unsplash.com/random/450x317?sig=61"
                  width="450"
                  className="gallery-image"
                />
              </li>
              <li>
                <img
                  height="317"
                  src="https://source.unsplash.com/random/450x317?sig=70"
                  width="450"
                  className="gallery-image"
                />
              </li>
              <li>
                <img
                  height="317"
                  src="https://source.unsplash.com/random/450x317?sig=112"
                  width="450"
                  className="gallery-image"
                />
              </li>
            </ul>
          </section>
          <section className="demo-gallery">
            <ul className="wrapper">
              <li>
                <img
                  height="317"
                  src="https://source.unsplash.com/random/450x317?sig=88"
                  width="450"
                  className="gallery-image"
                />
              </li>
              <li>
                <img
                  height="317"
                  src="https://source.unsplash.com/random/450x317?sig=35"
                  width="450"
                  className="gallery-image"
                />
              </li>
              <li>
                <img
                  height="317"
                  src="https://source.unsplash.com/random/450x317?sig=92"
                  width="450"
                  className="gallery-image"
                />
              </li>
            </ul>
          </section> */}
          <section className="demo-text">
            <div className="wrapper text">GLOWONYOURTERMS</div>
          </section>
          {/* <footer className="df aic jcc">
            <p>
              Images from <a href="https://unsplash.com/">Unsplash</a>
            </p>
          </footer> */}
        </div>
      </div>

      <Modal
        show={showAllServicesModal}
        onHide={handleAllServicesModalClose}
        className="all-services-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Todos nuestros servicios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="all-services-modal__content">
            <Accordion defaultActiveKey="0">
              {Object.keys(groupedServices).length > 0 && !loading ? (
                Object.entries(groupedServices).map(
                  ([categoryName, services], index) => (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>{categoryName}</Accordion.Header>
                      <Accordion.Body>
                        {services.map((service, key) => (
                          <div
                            className="all-services-modal__service"
                            key={key}
                            onClick={() => {
                              // setAppointmentData((prevState) => ({
                              //   ...prevState,
                              //   productSelection: service,
                              // }));
                              // setShowBookModal(true);
                              window.location.replace(
                                `https://cuultcenter.as.me/?appointmentType=${service.id},`
                              );
                              setShowAllServicesModal(false);
                            }}
                          >
                            <div
                              className="service__info__media"
                              style={{
                                backgroundImage: `url(${service.image})`,
                              }}
                            ></div>
                            <div className="service__info">
                              <h5>{service.name}</h5>
                              <p style={{ whiteSpace: "pre-wrap" }}>
                                {service.description
                                  .split("\n")
                                  .filter((line) => line.trim() !== "")
                                  .map((line, index) => (
                                    <span key={index}>
                                      {index > 0 ? "+ " : ""}
                                      {line}
                                      <br />
                                    </span>
                                  ))}
                              </p>
                            </div>
                            {/* <div>
                            <h5>{service.name}</h5>
                            <img src={rightArrowSmall} alt={service.name} />

                            </div> */}
                            <div className="d-flex justify-content-between justify-content-lg-start flex-lg-column h-100">
                              <span>{formatMoney(service.price)}</span>
                              <span className="service__info__duration">
                                <img
                                  src={durationIconBlue}
                                  alt={service.name}
                                  className="me-2"
                                />
                                ({service.duration} min)
                              </span>
                            </div>
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                )
              ) : (
                <p>Cargando...</p>
              )}
            </Accordion>

            {/* {isMobile ? (
              <>
                {showAllServicesMobile ? (
                  services?.map((service, key) => (
                    <div
                      className="all-services-modal__service"
                      key={key}
                      onClick={() => {
                        setAppointmentData((prevState) => ({
                          ...prevState,
                          productSelection: services[key],
                        }));
                        setShowBookModal(true);
                        setShowAllServicesModal(false);
                      }}
                    >
                      <h5>{service.name}</h5>
                      <img src={rightArrowSmall} alt={service.name} />
                    </div>
                  ))
                ) : (
                  <>
                    {services?.slice(0, 6).map((service, key) => (
                      <div
                        className="all-services-modal__service"
                        key={key}
                        onClick={() => {
                          setAppointmentData((prevState) => ({
                            ...prevState,
                            productSelection: services[key],
                          }));
                          setShowBookModal(true);
                          setShowAllServicesModal(false);
                        }}
                      >
                        <h5>{service.name}</h5>
                        <img src={rightArrowSmall} alt={service.name} />
                      </div>
                    ))}
                    {services?.length > 6 && (
                      <button onClick={showAllServicesMobileModal}>
                        Mostrar todos
                      </button>
                    )}
                  </>
                )}
              </>
            ) : (
              services?.map((service, key) => (
                <div
                  className="all-services-modal__service"
                  key={key}
                  onClick={() => {
                    setAppointmentData((prevState) => ({
                      ...prevState,
                      productSelection: services[key],
                    }));
                    setShowBookModal(true);
                    setShowAllServicesModal(false);
                  }}
                >
                  <h5>{service.name}</h5>
                  <img src={rightArrowSmall} alt={service.name} />
                </div>
              ))
            )} */}
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default Home;
