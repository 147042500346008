import React from "react";

import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

const onCreateOrder = (data, actions) => {
  return actions.order.create({
    purchase_units: [
      {
        amount: {
          value: "0.01",
        },
      },
    ],
  });
};

const onApproveOrder = (data, actions) => {
  return actions.order.capture().then((details) => {
    const name = details.payer.name.given_name;
    alert(`Transaction completed by ${name}`);
  });
};

function PayPalCheckout() {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  return (
    <PayPalButtons
      style={{ layout: "horizontal", tagline: false }}
      createOrder={(data, actions) => onCreateOrder(data, actions)}
      onApprove={(data, actions) => onApproveOrder(data, actions)}
    />
  );
}

export default PayPalCheckout;
